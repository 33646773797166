export const UserType = Object.freeze({
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  READ_ONLY_ADMIN: "READ_ONLY_ADMIN",
  GATE_MANAGER: "GATEMANAGER",
  GATE_CLERK: "GATECLERK",
  THIRD_PARTY_ADMIN: "THIRD_PARTY_ADMIN",
  DISPATCHER: "DISPATCHER",
});

export const AccountType = Object.freeze({
  BUYER: "Buyer",
  SUPPLIER: "Supplier",
  ADMIN: "Admin",
});

export const SubscriptionType = Object.freeze({
  MARKETPLACE_ONLY: "MARKETPLACE_ONLY",
  GMS_LITE: "GMS_LITE",
  GMS_PRO: "GMS_PRO",
});

export const AuthorityType = Object.freeze({
  ACCOUNT: "ACCOUNT",
  THIRD_PARTY: "THIRD_PARTY",
});

export const PaymentType = Object.freeze({
  ACH: "ACH",
  CARD: "CARD",
});

export const VerificationType = Object.freeze({
  INSTANT: "INSTANT",
  MICRO_DEPOSIT: "MICRO_DEPOSIT",
});

export const BankAccountType = Object.freeze({
  COMPANY_CHECKING: { value: "COMPANY_CHECKING", label: "Company - Checking" },
  COMPANY_SAVINGS: { value: "COMPANY_SAVINGS", label: "Company - Savings" },
  INDIVIDUAL_CHECKING: {
    value: "INDIVIDUAL_CHECKING",
    label: "Individual - Checking",
  },
  INDIVIDUAL_SAVINGS: {
    value: "INDIVIDUAL_SAVINGS",
    label: "Individual - Savings",
  },
});

export const LogoType = Object.freeze({
  LOGO_TRANSPARENT: "logo-transparent",
  LOGO_WHITE: "logo-white",
});

export const UserTypeName = Object.freeze({
  GATE_CLERK: "Gate Clerk",
  GATE_MANAGER: "Gate Manager",
  DISPATCHER: "Dispatcher",
  ADMIN: "Administrator",
  READ_ONLY_ADMIN: "Read-Only Administrator",
  ACCOUNT_OWNER: "Account Owner",
});

export const ZoneMapColors = Object.freeze({
  ACTIVE: "rgba(0,0,255,0.84)",
  INACTIVE: "rgb(0,0,0)",
});

export const EquipmentTypesMap = Object.freeze({
  CONTAINER_20_TO_45: "Container/Chassis (20'/40'/45')",
  CONTAINER_40_TO_53: "Container/Chassis (40'/45'/53')",
  CONTAINER_20_TO_40: "Container/Chassis (20'/40')",
  CONTAINER_45: "Container/Chassis (45')",
  TRUCK_AND_TRAILER: "Truck + Trailer",
  TRUCK_ONLY: "Truck Only",
  TRUCK_TWENTY_FIVE: "Truck Only (25')",
  TRAILER_FIFTY_THREE: "Trailer (53')",
  REFRIGERATED: "Refrigerated Plug In",
});

export const EquipmentLengthsConstant = Object.freeze({
  TWENTY_FIVE_FOOT: "25' MAX (Truck Only)",
  FORTY_FIVE_FOOT: "45' MAX (Small Container)",
  FIFTY_THREE_FOOT: "53' MAX (Large Container)",
  EIGHTY_THREE_FOOT: "83' MAX (Truck + Trailer)",
});

export const AssetTypesMap = Object.freeze({
  CONTAINER_LOADED: "Container / Chassis (Loaded)",
  CONTAINER_EMPTY: "Container / Chassis (Empty)",
  CHASSIS_ONLY: "Chassis Only",
  TRUCK_CONTAINER_LOADED: "Truck + Container / Chassis (Loaded)",
  TRUCK_CONTAINER_EMPTY: "Truck + Container / Chassis (Empty)",
  TRUCK_TRAILER_LOADED: "Truck + Trailer (Loaded)",
  TRUCK_TRAILER_EMPTY: "Truck + Trailer (Empty)",
  TRUCK_ONLY: "Truck Only",
  TRAILER_LOADED: "Trailer (Loaded)",
  TRAILER_EMPTY: "Trailer (Empty)",
  REEFER_LOADED_PLUGIN: "Refrigerated (Loaded) (Plug In)",
  REEFER_LOADED_NO_PLUGIN: "Reefer (Loaded) (No Plug In)",
  TRUCK_REEFER_LOADED_PLUGIN: "Truck + Reefer (Loaded) (Plug In)",
  TRUCK_REEFER_LOADED_NO_PLUGIN: "Truck + Reefer (Loaded) (No Plug In)",
});

export const PostMessageStatus = Object.freeze({
  SUCCESS: "success",
  CANCEL: "cancel",
});

export const BookingSubscriptionStatus = Object.freeze({
  ACTIVE: "Active",
  DECLINED: "Declined",
  CANCELLED: "Cancelled",
  NONE: "None",
});

export const EquipmentTypeForm = Object.freeze({
  CONTAINER_LOADED: "CONTAINER_LOADED",
  TRUCK_CONTAINER_LOADED: "TRUCK_CONTAINER_LOADED",
  CONTAINER_EMPTY: "CONTAINER_EMPTY",
  TRUCK_CONTAINER_EMPTY: "TRUCK_CONTAINER_EMPTY",
  CHASSIS_ONLY: "CHASSIS_ONLY",
  TRUCK_TRAILER_LOADED: "TRUCK_TRAILER_LOADED",
  TRAILER_LOADED: "TRAILER_LOADED",
  REEFER_LOADED_NO_PLUGIN: "REEFER_LOADED_NO_PLUGIN",
  TRUCK_REEFER_LOADED_PLUGIN: "TRUCK_REEFER_LOADED_PLUGIN",
  TRUCK_REEFER_LOADED_NO_PLUGIN: "TRUCK_REEFER_LOADED_NO_PLUGIN",
  REEFER_LOADED_PLUGIN: "REEFER_LOADED_PLUGIN",
  TRUCK_TRAILER_EMPTY: "TRUCK_TRAILER_EMPTY",
  TRAILER_EMPTY: "TRAILER_EMPTY",
  TRUCK_ONLY: "TRUCK_ONLY",
});

export const BookingTransactionTypes = Object.freeze({
  BOOKING_CHARGE: "Booking Payment",
  CANCELLATION_REFUND: "Cancellation Refund",
  OVERAGE_CHARGE: "Overage Payment",
  DATE_ADJUST_CHARGE: "Date Adjust Payment",
  DATE_ADJUST_REFUND: "Date Adjust Refund",
  OVERSTAY_CHARGE: "Overstay Payment",
  ADD_SPACE_CHARGE: "Add Space Charge",
  SPACE_REMOVED_REFUND: "Space Removed Refund",
  DAILY_OVERAGE_CHARGE: "Daily Overage Payment",
  FINE_ASSESSMENT_CHARGE: "Fine Assessment Charge",
  FINE_ASSESSMENT_REFUND: "Fine Assessment Refund",
});

export const DateFormats = Object.freeze({
  DATE: "MM/DD/YYYY hh:mm A",
  DATE_RESERVATION: "MM/DD/YYYY",
  DAY: "MM/DD/YYYY",
});

export const WaitListStatus = Object.freeze({
  PENDING: "Pending",
  APPROVED: "Approved",
  DENIED: "Denied",
});
const lengthOrder = [
  "",
  "TWENTY_FIVE_FOOT",
  "FORTY_FIVE_FOOT",
  "FIFTY_THREE_FOOT",
  "EIGHTY_THREE_FOOT",
];
export const compareObjectsAscending = (a, b, sortBy) => {
  const aValue = a[sortBy?.name];
  const bValue = b[sortBy?.name];

  if (!aValue && !bValue) return 0;
  if (!aValue) return -1;
  if (!bValue) return 1;

  if (sortBy?.name === "bookingNumber") {
    return aValue - bValue;
  }
  if (sortBy?.name === "bookingCreatedOn") {
    return new Date(aValue) - new Date(bValue);
  }
  if (sortBy?.name === "equipmentLength") {
    return (
      lengthOrder.indexOf(a.equipmentLength) -
      lengthOrder.indexOf(b.equipmentLength)
    );
  }
  if (typeof aValue === "string") {
    return aValue.localeCompare(bValue);
  } else {
    return aValue - bValue;
  }
};

export const compareObjectsDescending = (a, b, sortBy) => {
  const aValue = a[sortBy?.name];
  const bValue = b[sortBy?.name];

  if (!aValue && !bValue) return 0;
  if (!aValue) return 1;
  if (!bValue) return -1;

  if (sortBy?.name === "bookingNumber") {
    return bValue - aValue;
  }
  if (sortBy?.name === "bookingCreatedOn") {
    return new Date(bValue) - new Date(aValue);
  }
  if (sortBy?.name === "equipmentLength") {
    return (
      lengthOrder.indexOf(b.equipmentLength) -
      lengthOrder.indexOf(a.equipmentLength)
    );
  }

  if (typeof aValue === "string") {
    return bValue.localeCompare(aValue);
  } else {
    return bValue - aValue;
  }
};

function convertToMinutes(duration) {
  const days = parseInt(duration?.match(/(\d+) Day\(s\)/)?.[1] || 0);
  const hours = parseInt(duration?.match(/(\d+) Hour\(s\)/)?.[1] || 0);
  const minutes = parseInt(duration?.match(/(\d+) Minute\(s\)/)?.[1] || 0);

  return days * 24 * 60 + hours * 60 + minutes;
}

export const sortDurationsAscending = (a, b, sortByName) => {
  const totalMinutesA = convertToMinutes(a[sortByName]);
  const totalMinutesB = convertToMinutes(b[sortByName]);
  return totalMinutesA - totalMinutesB;
};

export const sortDurationsDescending = (a, b, sortByName) => {
  const totalMinutesA = convertToMinutes(a[sortByName]);
  const totalMinutesB = convertToMinutes(b[sortByName]);
  return totalMinutesB - totalMinutesA;
};
export const mMdDYYYYFormatAscending = (a, b, sortBy) => {
  const [monthA, dayA, yearA] = a[sortBy?.name]?.split("/");
  const [monthB, dayB, yearB] = b[sortBy?.name]?.split("/");

  const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
  const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
  return dateA - dateB;
};

export const mMdDYYYYFormatDescending = (a, b, sortBy) => {
  const [monthA, dayA, yearA] = a[sortBy?.name]?.split("/");
  const [monthB, dayB, yearB] = b[sortBy?.name]?.split("/");
  const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
  const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
  return dateB - dateA;
};

function convertToYYYYMMDD(dateString) {
  // Split the "MM/DD/YYYY" date string
  const [month, day, year] = dateString.split("/");

  // Return the date in "YYYY-MM-DD" format
  return `${year}-${month?.padStart(2, "0")}-${day?.padStart(2, "0")}`;
}

function isValidYYYYMMDD(dateString) {
  // Regular expression to match "YYYY-MM-DD" format
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

  // Test the dateString against the regex
  return regex.test(dateString);
}

export const extractDay = (dateString) => {
  const newDate = isValidYYYYMMDD(dateString)
    ? dateString
    : convertToYYYYMMDD(dateString);
  return newDate?.split("-")[2];
};

// Function to encode the username
export const encodeUsername = (username) => {
  return btoa(username); // Base64 encode the username
};

// Function to decode the username
export const decodeUsername = (encodedUsername) => {
  return atob(encodedUsername); // Base64 decode the username
};

// Save encoded username to local storage
export const saveUsernameToLocalStorage = (username, key) => {
  const encodedUsername = encodeUsername(username);
  localStorage.setItem(`${key}`, encodedUsername);
};

// Retrieve and decode username from local storage
export const getUsernameFromLocalStorage = (key) => {
  const encodedUsername = localStorage.getItem(key);
  if (!!encodedUsername) {
    return decodeUsername(encodedUsername);
  }
  return null;
};

export const checkCookie = (cookieName) => {
  // Get all cookies
  const cookies = document.cookie.split(";");
  console.log("cookies", cookies);

  // Iterate through cookies
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // Check if the cookie starts with the specified name
    if (cookie.startsWith(cookieName + "=")) {
      return true; // Cookie found
    }
  }

  return false; // Cookie not found
};

export const isImageFile = (file) => {
  let fileType = file.type;
  let validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  return validImageTypes.includes(fileType);
};

export const s3BucketUrl =
  "https://smartparking-default-files.s3.us-west-1.amazonaws.com/";

export const validateFiles = (allUploadFiles) => {
  for (let i = 0; i < allUploadFiles.length; i++) {
    let fileToUpload = allUploadFiles[i];
    let file = fileToUpload.files ? fileToUpload.files[0] : "";

    // Validate file size
    if (file.size > 20480000) {
      setErrorMessage("Selected file is too large. Max file size is 20 MB.");
      return false;
    }

    // Check if it's an image file
    if (fileToUpload.isImageFile) {
      if (file && !isImageFile(file)) {
        setErrorMessage(
          "File selected for listing image is not an image file."
        );
        return false;
      }
    }
  }
  // If all validations pass
  return true;
};
