import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal } from "react-router-modal";
import { ajaxRequest } from "../../../ajax";
import Busy from "../../../components/Busy";
import { toast } from "react-toastify";
import "../../../css/views/createEditLocation.css";
import { TSYS_URL, DEVICE_ID } from "./checkoutConstants";

function CreditCardModalPopUp({
  setCheckedButtonPayment,
  id,
  setLoadPaymentMethod,
  loadPaymentMethod,
  setCvv,
  cvv,
  setAchPayment,
}) {
  const [manifest, setManifest] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardHolderNameValidate, setCardHolderNameValidate] = useState("");
  const [expirationDateValidate, setExpirationDateValidate] = useState("");
  const [cardNumberValidate, setCardNumberValidate] = useState("");
  const [zipCodeValidate, setZipCodeValidate] = useState("");
  const [cvvValidate, setCvvValidate] = useState("");

  const [cardType, setCardType] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [maskedCardNumber, setMaskedCardNumber] = useState("");
  const [tsepToken, setTsepToken] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [responseCode, setResponseCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ajaxRequest({
      url: "tsys/getManifest",
      method: "GET",
      type: "GET",
      contentType: "application/json",
      dataType: "json",
      success: (res) => {
        setManifest(res?.manifestId);

        let url = `${TSYS_URL}${DEVICE_ID}?${res?.manifestId}`;
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
      },
      error: (err) => {
        toast.error(err?.message);
      },
    });
  }, []);

  useEffect(() => {
    function sayHello() {
      const cardNum = document.getElementById("tsep-cardNum");
      if (!!cardNum?.value) {
        setMaskedCardNumber(cardNum?.value?.slice(-4));
        if (cardNum?.value !== cardNumberValidate) {
          if (!!errorMessage) setErrorMessage("");
        }

        setCardNumberValidate(cardNum?.value);
      }
      const cardName = document.getElementById("tsep-cardHolderName");
      if (!!cardName?.value) {
        setCardHolderName(cardName?.value);
        if (cardNum?.value !== cardHolderNameValidate) {
          if (!!errorMessage) setErrorMessage("");
        }
        setCardHolderNameValidate(cardName?.value);
      }
      const cvv = document.getElementById("tsep-cvv2");
      if (cvv?.value) {
        setCvv(cvv?.value);
        if (cvv?.value !== cvvValidate) {
          if (!!errorMessage) setErrorMessage("");
        }
        setCvvValidate(cvv?.value);
      }
      const expirationDate = document.getElementById("tsep-datepicker");
      if (expirationDate?.value) {
        setExpirationDate(expirationDate?.value);
        if (expirationDate?.value !== expirationDateValidate) {
          if (!!errorMessage) setErrorMessage("");
        }
        setExpirationDateValidate(expirationDate?.value);
      }
      const zipCode = document.getElementById("tsep-zipCode");
      if (zipCode?.value) {
        setZipCode(zipCode?.value);
        if (zipCode?.value !== zipCode) {
          if (!!errorMessage) setErrorMessage("");
        }
        setZipCodeValidate(zipCode?.value);
      }
      setCardType(window.localStorage.getItem("cardType"));
      setTsepToken(window.localStorage.getItem("tsepToken"));
      setErrorMessage(window.localStorage.getItem("errorMessage"));
      setResponseCode(window.localStorage.getItem("responseCode"));
    }
    const intervalId = setInterval(sayHello, 12000);
  }, []);

  // useEffect(() => {
  //   function sayHello() {
  //     if (!!document.getElementById("tsep-cardNum")) {
  //       document
  //         .getElementById("tsep-cardNum")
  //         .addEventListener("change", function (ele) {
  //           console.log("ele", ele?.target.value);
  //           setCardNumberValidate(ele?.target?.value);
  //         });
  //     }
  //   }

  //   const intervalId = setInterval(sayHello, 12000);
  // }, [cardNumberValidate]);
  function handleChange(ele) {
    setCardNumberValidate(ele?.target?.value);
  }

  useEffect(() => {
    if (responseCode === "A0000") {
      window.localStorage.setItem("errorMessage", "");
      setErrorMessage("");
    }
  }, [responseCode]);
  useEffect(() => {
    const checkElementPresence = () => {
      const element = document.querySelector("#tsep-cardNum");
      Busy.set(true);
      // document.addEventListener("change", handleChange);
      if (element) {
        Busy.set(false);
      } else {
        //console.log("Element is not present");
      }
    };
    document.addEventListener("DOMContentLoaded", checkElementPresence);
    document.addEventListener("DOMSubtreeModified", checkElementPresence);
    return () => {
      document.removeEventListener("DOMContentLoaded", checkElementPresence);
      document.removeEventListener("DOMSubtreeModified", checkElementPresence);
    };
  }, []);
  useEffect(() => {
    const inputFields = document.querySelectorAll("input");

    inputFields.forEach((input) => {
      if (input.id && !input.name) {
        input.remove();
      }
    });
  });

  const scriptContentTsep = `
  function tsepHandler(eventType, event) {
   console.log("eventType",eventType)
   console.log("event",event)
     if(eventType === "TokenEvent" ){
      if(event?.status === "PASS"){
      window.localStorage.setItem("cardType",event.cardType)
      window.localStorage.setItem("tsepToken",event.tsepToken)
      window.localStorage.setItem("responseCode",event.responseCode)
      }else if(event?.status === "FAILURE" || event?.status ==="FAIL"){
      window.localStorage.setItem("responseCode",event.responseCode)
          }
     }else {
      window.localStorage.setItem("responseCode","")

     }
    if(event?.status ==="FAIL" || event?.status === "FAILURE") {
      window.localStorage.setItem("errorMessage",event?.message)
    }
    // if(eventType === "FocusOutEvent") {
    //   window.localStorage.setItem("errorMessage","")
    // }
}`;
  // console.log("responseCode", responseCode);
  // console.log("maskedCardNumberValidate", cardNumberValidate);
  // console.log("cardHolderNameValidate", cardHolderNameValidate);
  // console.log("zipCodeValidate", zipCodeValidate);
  // console.log("cvvValidate", cvvValidate);
  // console.log("expirationDateValidate", expirationDateValidate);
  // console.log("errorMessage", errorMessage);

  const handleSubmit = () => {
    const name = cardHolderName.split(" ");
    let firstName;
    let lastName;
    if (name.length > 0) {
      firstName = name[0];
      lastName = name[1];
    }
    // console.log("maskedCardNum", maskedCardNumber);
    // console.log("cardHolderName", cardHolderName);
    // console.log("expirationDate", expirationDate);
    // console.log("cvv", cvv);
    // console.log("zipCode", zipCode);
    // console.log("firstName", firstName);
    // console.log("lastName", lastName);
    // console.log("cardType", cardType);
    Busy.set(true);

    ajaxRequest({
      url: "payment-method/createPaymentMethod",
      data: JSON.stringify({
        accountId: id,
        cardNumber: maskedCardNumber,
        tsepToken: tsepToken,
        firstName: firstName,
        lastName: lastName,
        cardBrand: cardType,
        expirationDate: expirationDate,
        cvv2: cvv,
        zip: zipCode,
      }),
      contentType: "application/json",
      method: "POST",
      success: (res) => {
        window.localStorage.removeItem("cardType", "");
        window.localStorage.removeItem("tsepToken", "");
        window.localStorage.removeItem("errorMessage", "");
        window.localStorage.removeItem("responseCode", "");
        Busy.set(false);
        toast.success("Credit card added successfully!");
        setCheckedButtonPayment("showPaymentDetailsCREDITCARD");
        setLoadPaymentMethod(!loadPaymentMethod);
        setAchPayment(false);
      },

      error: (jqXHR) => {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON
          ? jqXHR.responseJSON.message
            ? jqXHR.responseJSON.message.trim()
            : ""
          : "";
        Busy.set(false);
        toast.error(errorMessage);
      },
    });
  };

  const handleCancel = () => {
    window.localStorage.removeItem("cardType", "");
    window.localStorage.removeItem("tsepToken", "");
    window.localStorage.removeItem("errorMessage", "");
    window.localStorage.removeItem("responseCode", "");

    setCheckedButtonPayment("showPaymentDetails");
  };
  return (
    <Modal className="app-modal app-modal-in">
      <div id="ssCreateEditLocation">
        <div className="modal-content ">
          <Helmet>
            {/* <script src={`${TSYS_URL}${DEVICE_ID}?${manifest}`}></script> */}
            <script type="text/javascript" defer>
              {scriptContentTsep}
            </script>
          </Helmet>
          <h1
            className="ss-report-header-title"
            style={{ textAlign: "center", marginTop: "17px" }}
          >
            Credit Card Details
          </h1>
          <div>
            <form method="POST" id="payment-form" className="formCreditCard">
              <div id="tsep-cardNumDiv" className="CardNumber">
                <label>CARD NUMBER</label>
              </div>
              <div id="tsep-cardHolderNameDiv" className="CardNumber">
                <label>CARDHOLDER NAME </label>
              </div>
              <div id="tsep-datepickerDiv" className="CardNumber">
                <label>EXPIRATION DATE </label>
              </div>
              <div id="tsep-cvv2Div" className="CardNumber">
                <label>CVV NUMBER</label>
              </div>
              <div id="tsep-zipCodeDiv" className="CardNumber">
                <label>ZIP CODE</label>
              </div>
              <div id="tsep-cardNumDiv" data-detect-card-type="Y"></div>
              <div style={{ color: "red", width: "200%" }}>{errorMessage}</div>
            </form>
            <div className="newbuttonDivSubmit">
              <button
                className="ss-button-secondary"
                style={{ width: "200px" }}
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={!(responseCode === "A0000")}
                className="ss-button-primary"
                style={
                  !(responseCode === "A0000")
                    ? {
                        pointerEvents: "none",
                        opacity: "0.5",
                        width: "200px",
                      }
                    : { width: "200px" }
                }
              >
                {!responseCode &&
                !!maskedCardNumber &&
                !!cardHolderName &&
                !!zipCode &&
                !!cvv &&
                !!expirationDate
                  ? !responseCode
                    ? "Save"
                    : "Validating Card..."
                  : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreditCardModalPopUp;
