import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import ReportFormDateInput from "../report/ReportFormDateInput";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import ReportFormContainer from "../report/ReportFormContainer";
import { ZoneAvailabilityWithGroupAndSort } from "./ZoneAvailabilityWithGroupAndSort";
import { getAllZonesByDate } from "../zonebuilder/zoneRequest";
import { searchRecordsByKeywordZoneAvailability } from "../../util/SearchUtils";
import Filter from "../../views/bookings/Filter";
import ReportEmpty from "../report/ReportEmpty";
import { DateFormats } from "../constants/securspace-constants";
import moment from "moment";
import { extractDay } from "../constants/securspace-constants";
import Busy from "../Busy";
const ZoneAvailabilityReport = (props) => {
  const { user } = props;

  const addDaysToToday = (numberOfDaysToAdd) => {
    return addDaysToDate(new Date(), numberOfDaysToAdd);
  };

  const addDaysToDate = (date, numberOfDaysToAdd) => {
    date.setDate(date.getDate() + numberOfDaysToAdd);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  };

  const defaultStartDate = addDaysToToday(-30);
  const defaultEndDate = addDaysToToday(0);

  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [allZones, setAllZones] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const reportFields = [
    { label: "Zone Name", name: "name" },
    { label: "Available Spaces", name: "currentAvailableSpaces" },
  ];

  useEffect(() => {
    let isMounted = true;
    Busy.set(true);

    getAllZonesByDate(startDate, endDate)
      .then(
        (promise) => {
          if (isMounted) {
            let data = promise.body;
            setAllZones(data);
          }
          Busy.set(false);
        },
        (error) => {
          if (isMounted) {
            toast(getErrorMessageForStandardResponse(error));
          }
          Busy.set(false);
        }
      )
      .catch((error) => {
        if (isMounted) {
          toast(getErrorMessageForStandardResponse(error));
        }
        Busy.set(false);
      });

    return () => {
      isMounted = false;
    };
  }, [startDate, endDate]);

  useEffect(() => {
    if (allZones && searchBox) {
      setFilteredData(
        searchRecordsByKeywordZoneAvailability(searchBox, allZones)
      );
    } else {
      setFilteredData(allZones);
    }
  }, [allZones, searchBox]);

  const searchChangeHandler = (event) => {
    const value = event.target.value;
    setSearchBox(value);
  };

  const handleStartDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(startDate);

    if (!(day1 === day2)) {
      setStartDate(date);
    }
  };

  const handleEndDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(endDate);

    if (!(day1 === day2)) {
      setEndDate(date);
    }
  };

  return (
    <ReportBackgroundContainer>
      <header>
        <ul className="breadcrumb">
          <li>Reports</li>
          <li>Zone Report</li>
        </ul>
        <h1 className="ss-report-header-title">Zone Availability Report</h1>
      </header>
      <div style={{ width: "49%" }}>
        <ReportFormContainer>
          <ReportFormDateInput
            label={"START DATE:"}
            date={startDate}
            onDateChange={handleStartDate}
          />
          <ReportFormDateInput
            label={"END DATE:"}
            date={endDate}
            onDateChange={handleEndDate}
          />
        </ReportFormContainer>
      </div>

      {allZones?.length > 0 && (
        <Filter
          searchBox={searchBox}
          searchChangeHandler={searchChangeHandler}
          placeHolder="Type any field to filter zone availability report result"
        />
      )}

      {!!filteredData && filteredData?.length > 0 ? (
        <>
          <ZoneAvailabilityWithGroupAndSort
            account={user}
            availableZones={filteredData}
            reportFields={reportFields}
            reportName="Zone Availability"
          />
          {filteredData?.length > 0 && (
            <div style={{ textAlign: "center" }}>
              {" "}
              You have reached end of the list
            </div>
          )}
          <div className="ss-supplier-active-bookings-endlist">
            <h6>{!!filteredData && filteredData.length > 0 ? " " : " "}</h6>
          </div>
        </>
      ) : (
        <ReportEmpty />
      )}

      {/* {allZones?.length > 0 && (
        <div style={{ textAlign: "center" }}>
          {" "}
          You have reached end of the list
        </div>
      )}
      <div className="ss-supplier-active-bookings-endlist">
        <h6>{!!allZones && allZones.length > 0 ? " " : " "}</h6>
      </div> */}
    </ReportBackgroundContainer>
  );
};

export default ZoneAvailabilityReport;
