import React from "react";
import ReportDataRow from "../../components/report/ReportDataRow";
import ReportData from "../../components/report/ReportData";
import {
  formatActive,
  formatBookingRate,
  formatDate,
  formatEquipmentLength,
  formatFrequency,
  formatRecurringPayoutAmount,
  formatSubscriptionStatus,
} from "../../util/booking/bookingUtil";
import { formatCurrencyValue } from "../../util/PaymentUtils";
import PropTypes from "prop-types";

const BookingCard = (props) => {
  const { booking } = props;

  return (
    <div>
      {booking ? (
        <ReportDataRow>
          <ReportData label={"Booking Number"}>
            {booking?.orderNumber || "-"}
          </ReportData>

          <ReportData label={"Customer"}>
            {booking?.buyerCompanyName || "-"}
          </ReportData>

          <ReportData label={"Start Date"}>
            {!!booking?.startDate ? formatDate(booking.startDate) : "-"}
          </ReportData>

          <ReportData label={"End Date"}>
            {!!booking?.endDate && booking?.frequency !== "RECURRING"
              ? formatDate(booking.endDate)
              : "-"}
          </ReportData>

          <ReportData label={"Number of Spaces"}>
            {booking?.numberOfSpaces || "-"}
          </ReportData>

          <ReportData label={"Location"}>
            {booking?.locationName || "-"}
          </ReportData>

          <ReportData label={"Brokered"}>
            {booking?.brokered.toString() || "-"}
          </ReportData>
          <ReportData label={"Frequency"}>
            {booking?.frequency || "-"}
          </ReportData>

          <ReportData label={"Equipment Length"}>
            {booking?.equipmentLength
              ? formatEquipmentLength(booking.equipmentLength)
              : "-"}
          </ReportData>
          <ReportData label={"Initial / Total Charge"}>
            ${booking?.initialSupplierPayoutAmount / 100 || "-"}
          </ReportData>

          {/* <ReportData label={"Initial / Total Charge"}>
            {booking?.initialSupplierPayoutAmount
              ? formatCurrencyValue(booking.initialSupplierPayoutAmount, true)
              : "-"}
          </ReportData> */}

          <ReportData label={"Recurring Charge"}>
            {booking?.recurringSupplierPayoutAmount
              ? `$${booking.recurringSupplierPayoutAmount / 100}`
              : "-"}
          </ReportData>

          <ReportData label={"Booking Rate"}>
            $ {booking?.rate / 100 || "-"}
            {/* {booking?.durationType ? formatBookingRate(booking) : "-"} */}
          </ReportData>

          <ReportData label={"Status"}>
            {!!booking?.status ? booking.status : "-"}
          </ReportData>
          <ReportData label={"Subscription Status"}>
            {!!booking?.bookingSubscriptionStatus
              ? formatSubscriptionStatus(booking?.bookingSubscriptionStatus)
              : "-"}
          </ReportData>

          <ReportData label={"Active"}>
            {!!booking?.active ? formatActive(booking.active) : "-"}
          </ReportData>
          <ReportData label={"Created On"}>
            {booking?.createdOn ? formatDate(booking.createdOn) : "-"}
          </ReportData>

          <ReportData label={"Zone"}>
            {booking?.locationZoneName || "-"}
          </ReportData>
        </ReportDataRow>
      ) : (
        ""
      )}
    </div>
  );
};

BookingCard.propTypes = {
  booking: PropTypes.shape({
    orderNumber: PropTypes.string,
    buyerCompanyName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    numberOfSpaces: PropTypes.number,
    locationName: PropTypes.string,
    brokered: PropTypes.bool,
    frequency: PropTypes.string,
    equipmentLength: PropTypes.string,
    initialSupplierPayoutAmount: PropTypes.number,
    recurringPayoutAmount: PropTypes.number,
    rate: PropTypes.number,
    status: PropTypes.string,
    active: PropTypes.bool,
    createdOn: PropTypes.string,
    locationZoneName: PropTypes.string,
  }),
};

export default BookingCard;
