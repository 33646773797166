import React from "react";
import { MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ReportKebabButton from "../../report/ReportKebabButton";
import ReportFlexRightContainer from "../../report/ReportFlexRightContainer";
import {
  ALL_MANAGER_ROLES,
  isRoleApproved,
  MANAGER_EDIT_ROLES,
  MANAGER_EDIT_ROLES2,
} from "../../../util/user/UserUtil";

const ZoneMenu = (props) => {
  const { zone, account } = props;

  let history = useHistory();

  const MyOptions = {
    EDIT_ZONE: "Edit Zone",
    VIEW_BOOKINGS: "View Bookings",
    VIEW_HISTORY: "View History",
  };

  const handleMenuOption = (option, zone) => {
    if (option === MyOptions.EDIT_ZONE) {
      history.push({
        pathname: "/zone-editor",
        state: { zone: zone }, // Make sure zoneData is valid
      });
    }

    if (option === MyOptions.VIEW_BOOKINGS) {
      localStorage.setItem("activeMenuItem", "bookings");
      localStorage.setItem("activeSubmenu", "supplier-bookings");
      history.push(`/supplier-bookings?zoneName=${zone.name}`);
      window.location.reload();
    }

    if (option === MyOptions.VIEW_HISTORY) {
      history.push(`/audits/zones?zoneId=${zone.id}`);
    }
  };

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>
        {isRoleApproved(MANAGER_EDIT_ROLES, account.userType) && (
          <MenuItem
            key={MyOptions.EDIT_ZONE}
            onClick={() => handleMenuOption(MyOptions.EDIT_ZONE, zone)}
          >
            {MyOptions.EDIT_ZONE}
          </MenuItem>
        )}
        {isRoleApproved(MANAGER_EDIT_ROLES2, account.userType) && (
          <MenuItem
            key={MyOptions.VIEW_BOOKINGS}
            onClick={() => handleMenuOption(MyOptions.VIEW_BOOKINGS, zone)}
          >
            {MyOptions.VIEW_BOOKINGS}
          </MenuItem>
        )}
        {isRoleApproved(ALL_MANAGER_ROLES, account.userType) && (
          <MenuItem
            key={MyOptions.VIEW_HISTORY}
            onClick={() => handleMenuOption(MyOptions.VIEW_HISTORY, zone)}
          >
            {MyOptions.VIEW_HISTORY}
          </MenuItem>
        )}
      </ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default ZoneMenu;
