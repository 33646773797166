import CryptoJS from "crypto-js";

const EncryptionUtil = (secretKey) => {
  // Encrypt the data using AES
  const encrypt = (data) => {
    try {
      const key = CryptoJS.enc.Utf8.parse(secretKey);
      const encryptedData = CryptoJS.AES.encrypt(data, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encryptedData.toString();
    } catch (error) {
      console.error("Encryption failed:", error);
      return null;
    }
  };

  return { encrypt };
};

export default EncryptionUtil;
