import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  requestBookings,
  requestCancelBooking,
} from "./requests/booking-requests";
import { toast } from "react-toastify";
import {
  getErrorMessageForStandardResponse,
  toastErrorMessage,
} from "../../util/NetworkErrorUtil";
import ReportBackgroundContainer from "../../components/report/ReportBackgroundContainer";
import BookingList from "./BookingList";
import Filter from "./Filter";
import GroupAndSort from "./GroupAndSort";
import _ from "underscore";
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from "../../util/report/ReportUtils";
import {
  searchRecordsByKeyword,
  searchRecordsByKeywordWaitListAvailability,
} from "../../util/SearchUtils";
import ReportEmpty from "../../components/report/ReportEmpty";
import Busy from "../../components/Busy";

const BookingsReport = (props) => {
  const reportFields = [
    { label: "Booking Number", name: "orderNumber" },
    { label: "Customer", name: "buyerCompanyName" },
    { label: "Start Date", name: "startDate" },
    { label: "End Date", name: "endDate" },
    { label: "Number of Spaces", name: "numberOfSpaces" },
    { label: "Brokered", name: "brokered" },
    { label: "Equipment Length", name: "equipmentLength" },
    { label: "Subscription Status", name: "bookingSubscriptionStatus" },
    { label: "Zone Name", name: "locationZoneName" },
  ];
  const lengthOrder = [
    "",
    "TWENTY_FIVE_FOOT",
    "FORTY_FIVE_FOOT",
    "FIFTY_THREE_FOOT",
    "EIGHTY_THREE_FOOT",
  ];

  const { account } = props;
  const [bookings, setBookings] = useState([]);
  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION_ASC);
  const [groupDirection, setGroupDirection] = useState(SORT_DIRECTION_ASC);
  const [searchBox, setSearchBox] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [loadApi, setLoadApi] = useState(false);

  const searchParams = useLocation().search;
  const zoneName = new URLSearchParams(searchParams).get("zoneName");

  useEffect(() => {
    if (!zoneName) setSearchBox("");
  }, [zoneName]);
  useEffect(() => {
    Busy.set(true);
    requestBookings(account.id)
      .then((response) => {
        let data = response.body;
        if (data && data.length > 0) {
          setBookings(data);
          if (!!zoneName) {
            setSearchBox(zoneName);
            setFilteredData(searchRecordsByKeyword(zoneName, data));
          } else {
            setFilteredData(data);
          }
        }
        Busy.set(false);
      })
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
  }, [account, zoneName, loadApi]);

  useEffect(() => {
    if (sortBy?.name) sortData(sortBy);
  }, [sortBy?.name, sortDirection, filteredData?.length]);

  const searchChangeHandler = (event) => {
    const value = event.target.value;
    setSearchBox(value);
    setFilteredData(
      searchRecordsByKeywordWaitListAvailability(value, bookings)
    );
  };

  const groupByChangeHandler = (event) => {
    const value = event.target.value;
    setGroupBy(value);
    groupData(value);
  };

  const groupData = (value) => {
    const groupedData = _.groupBy(filteredData, value);
    const toArray = _.toArray(groupedData);
    const flattened = _.flatten(toArray);
    setFilteredData(flattened);
  };

  const sortByChangeHandler = (event) => {
    const value = event.target.value;
    setSortBy(value);
  };

  const sortData = (value) => {
    let sortedData = [...filteredData];

    if (sortDirection === SORT_DIRECTION_ASC) {
      sortedData.sort((a, b) => {
        const aValue = a[value?.name];
        const bValue = b[value?.name];

        if (aValue == null && bValue == null) return 0;
        if (aValue == null) return -1;
        if (bValue == null) return 1;

        if (value?.name === "equipmentLength") {
          return (
            lengthOrder.indexOf(a.equipmentLength) -
            lengthOrder.indexOf(b.equipmentLength)
          );
        }

        if (typeof aValue === "string") {
          return aValue.localeCompare(bValue);
        } else {
          return aValue - bValue;
        }
      });
    } else {
      sortedData.sort((a, b) => {
        const aValue = a[value?.name];
        const bValue = b[value?.name];

        if (aValue == null && bValue == null) return 0;
        if (aValue == null) return 1;
        if (bValue == null) return -1;
        if (value?.name === "equipmentLength") {
          return (
            lengthOrder.indexOf(b.equipmentLength) -
            lengthOrder.indexOf(a.equipmentLength)
          );
        }

        if (typeof aValue === "string") {
          return bValue.localeCompare(aValue);
        } else {
          return bValue - aValue;
        }
      });
    }

    setFilteredData(sortedData);
  };

  const sortDirectionChangeHandler = () => {
    setSortDirection(
      sortDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
  };

  const groupDirectionChangeHandler = () => {
    setGroupDirection(
      groupDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
    setFilteredData([...filteredData.reverse()]);
  };

  const handleCancelBooking = () => {
    Busy.set(true);
    requestCancelBooking(bookingId)
      .then(() => {
        Busy.set(false);
        setShow(false);
        setLoadApi(true);
        toast.success("Booking cancelled successfully!");
      })
      .catch(toastErrorMessage);
  };

  return (
    <div>
      <ReportBackgroundContainer>
        <header>
          <ul className="breadcrumb">
            <li>BOOKINGS</li>
            <li>All BOOKINGS</li>
          </ul>
          <h1 className="ss-report-header-title">BOOKINGS </h1>
        </header>

        {bookings.length > 0 ? (
          <>
            <Filter
              searchBox={searchBox}
              searchChangeHandler={searchChangeHandler}
              placeHolder="Type any field to filter booking report result"
            />
            <GroupAndSort
              groupByOptions={reportFields}
              groupByChangeHandler={groupByChangeHandler}
              groupBy={groupBy}
              groupDirection={groupDirection}
              groupDirectionChangeHandler={groupDirectionChangeHandler}
              sortBy={sortBy}
              sortByChangeHandler={sortByChangeHandler}
              sortDirection={sortDirection}
              sortDirectionChangeHandler={sortDirectionChangeHandler}
              asc={SORT_DIRECTION_ASC}
              desc={SORT_DIRECTION_DESC}
            />

            {filteredData.length > 0 ? (
              <BookingList
                account={account}
                bookings={filteredData}
                setShow={setShow}
                setBookingId={setBookingId}
                setOrderNumber={setOrderNumber}
              />
            ) : (
              <ReportEmpty />
            )}
          </>
        ) : (
          <ReportEmpty />
        )}
        {show && (
          <div className="unselectable">
            <div
              className="modal-dialog"
              style={{
                position: "absolute",
                top: "50%",
                left: "56%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="modal-content" style={{ width: "70%" }}>
                <div className="popup-header-remove">
                  <h1>Cancel booking {orderNumber}</h1>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => setShow(false)}
                  >
                    <img alt="" src="../app-images/close.png" />
                  </button>
                </div>
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "50px",
                    marginBottom: "50px",
                  }}
                >
                  <span>Are you sure you want to cancel this booking?</span>
                </div>
                <div style={{ marginBottom: "18px" }}>
                  <button
                    className="ss-button-secondary"
                    onClick={() => setShow(false)}
                    style={{ marginLeft: "18%", width: "30%" }}
                  >
                    Cancel
                  </button>
                  <button
                    className="ss-button-primary"
                    onClick={() => handleCancelBooking()}
                    style={{ marginLeft: "5%", width: "30%" }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </ReportBackgroundContainer>
    </div>
  );
};

export default BookingsReport;
