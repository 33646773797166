import React, { useEffect, useState, useRef } from "react";
import useQuery from "../../../util/query/useQuery";
import useJwt from "../../../util/jwt/useJwt";
import {
  getPaymentMethods,
  getReservation,
  getFineById,
  getZone,
} from "../requests/thirdparty-requests";
import { getErrorMessageForStandardResponse } from "../../../util/NetworkErrorUtil";
import { ErrorRounded } from "@material-ui/icons";
import { useParams } from "react-router";
import ReservationCheckout from "../../../components/reservation/ReservationCheckout";
import DisplayChargeAmount from "../../../components/chargeAmount/DisplayChargeAmount";
import moment from "moment";
import SolidButton from "../../../components/form/SolidButton";
import ReportBackgroundContainer from "../../../components/report/ReportBackgroundContainer";
import ReportHeader from "../../../components/report/ReportHeader";
import ReportTitle from "../../../components/report/ReportTitle";
import { toast } from "react-toastify";
import ZoneCheckout from "../../../components/zone/ZoneCheckout";
import { PostMessageStatus } from "../../../components/constants/securspace-constants";
import OutlinedButton from "../../../components/form/OutlinedButton";
import Busy from "../../../components/Busy";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-router-modal";
import { ajaxRequest } from "../../../ajax";
import "../../../css/views/createEditLocation.css";
import "../../../css/theme/forms.css";
import "../../../css/theme/forms-block.css";
import "../../../css/theme/buttons.css";
import { stateConstant } from "./checkoutConstants";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./checkout.css";
import CreditCardModalPopUp from "./creditCardModalPopup";
import DisplayAddedPaymentMethod from "./displayAddedPaymentMethod";
import CrediCardDisplayPopUp from "./creditCardDisplayPopUp";
import ReportDataRow from "../../../components/report/ReportDataRow";
import ReportData from "../../../components/report/ReportData";
import SecurityDepositSuccessfull from "./securityDepositSuccessfull";
import FineCheckout from "./fineCheckout/fineCheckout";
const Checkout = () => {
  const { reservationId } = useParams();
  const { token } = useQuery();

  const {
    id,
    username,
    fineAmount,
    exp,
    securityDepositAmount,
    securityDepositRequired,
  } = useJwt();
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const bankNameRef = useRef(null);
  const address1Ref = useRef(null);
  const address2Ref = useRef(null);
  const cityRef = useRef(null);
  const zipRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const [expirePopUp, setExpirePopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [reservation, setReservation] = useState({});
  const [zone, setZone] = useState({});
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const [checkedButtonPayment, setCheckedButtonPayment] = useState("");
  const [securityDepositAmountState, setSecurityDepositAmountState] = useState(
    (securityDepositAmount / 100).toFixed(2)
  ); // please change this to securityDepositAmount instead of 1000 once testing is done
  const [securityDepositRequiredState, setSecurityDepositRequiredState] =
    useState(securityDepositRequired); // change this to normal value once testing is done

  const [securityInvoiceNumber, setSecurityInvoiceNumber] = useState("");
  const [securityDepositPaymentType, setSecurityDepositPaymentType] =
    useState("");

  const [securityDepositnickName, setsecurityDepositnickName] = useState("");
  const [sourceSecurityDeposit, setsourceSecurityDeposit] = useState("");
  const [dateInitiatedSecurityDeposit, setdateInitiatedSecurityDeposit] =
    useState("");
  const [securityDepositPaymentStatus, setsecurityDepositPaymentStatus] =
    useState(false);
  const [fineByIdDetails, setFineByIdDetails] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bookSpaceForm, setBookSpaceForm] = useState(false);
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [checkNumber, setCheckNumber] = useState("");
  const [disablePayment, setDisablePayment] = useState(false);
  const [errorValidationMessageZip, setErrorValidationMessageZip] =
    useState("");
  const [errorValidationMessagePhone, setErrorValidationMessagePhone] =
    useState("");
  const [loadPaymentMethod, setLoadPaymentMethod] = useState(false);
  const [cvv, setCvv] = useState("");
  const [selectingCreditCardIndex, setSelectingCreditCardIndex] =
    useState(null);
  const [selectingAchIndex, setSelectingAchIndex] = useState(null);
  const [showSecurityDepositPayment, setShowSecurityDepositPayment] =
    useState(false);

  const [showAchBookSpaceModal, setShowAchBookSpaceModal] = useState(false);
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);
  const [achPayment, setAchPayment] = useState(false);
  const [selectedIndex1, setSelectedIndex1] = useState(null);
  const [selectedIndex2, setSelectedIndex2] = useState(null);

  const SOURCE = "sps-checkout";
  const Android = window.Android;
  const webkit = window.webkit;

  useEffect(() => {
    window.closeCheckoutWindow = () => {
      window.close();
    };
  }, []);

  useEffect(() => {
    if (moment().isAfter(moment.unix(exp))) {
      setErrorMessage(
        "Sorry, your reservation has expired, you will need to resubmit your inquiry and try again later."
      );
      setExpirePopUp(true);
    }
  }, [exp, setErrorMessage]);
  console.log("fineAmount", fineAmount);
  console.log("reservationId", reservationId);

  useEffect(() => {
    Busy.set(true);
    if (Number(fineAmount) > 0) {
      getFineById(reservationId)
        .then((responseFine) => {
          setFineByIdDetails(responseFine.body);
          Busy.set(false);
        })
        .catch((error) => {
          setErrorMessage(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        });
    } else {
      getReservation(reservationId)
        .then((responseReservation) => {
          setReservation(responseReservation.body);
          getZone(responseReservation.body.zoneId)
            .then((zoneResponse) => {
              setZone(zoneResponse.body);
            })
            .catch((error) => {
              setErrorMessage(getErrorMessageForStandardResponse(error));
            });
          Busy.set(false);
        })
        .catch((error) => {
          setErrorMessage(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        });
    }
  }, []);
  useEffect(() => {
    if (selectedIndex1 === null && selectedIndex2 === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setSelectingCreditCardIndex(selectedIndex1);
    setSelectingAchIndex(selectedIndex2);
  }, [selectedIndex1, selectedIndex2, selectingCreditCardIndex]);

  useEffect(() => {
    Busy.set(true);
    getPaymentMethods(id)
      .then((responsePayment) => {
        setPaymentMethods(responsePayment.body);
        responsePayment?.body?.forEach((paymentMethod) => {
          if (paymentMethod?.defaultPaymentMethodForProfile)
            setPaymentMethodId(paymentMethod.id);

          if (paymentMethod.paymentProfileId && paymentMethod.type === "ACH") {
            setDisabled(false);
            // setCheckedButtonPayment("abc");
            setAccountType(paymentMethod?.accountType);
            setBankName(paymentMethod?.bankName);
            //setDisablePayment(true);
            setCheckedButtonPayment("showPaymentDetails");
          } else if (paymentMethod.type === "CARD") {
            setDisabled(false);
            //setDisablePayment(true);
            setCheckedButtonPayment("showPaymentDetails");
          }
        });

        Busy.set(false);
      })
      .catch((error) => {
        setErrorMessage(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
  }, [loadPaymentMethod]);

  const handlePaymentIdSelected = (event) => {
    setPaymentMethodId(event.target.id);
    setDisabled(false);
  };

  const handlePaymentMethodAdded = () => {
    getPaymentMethods(id)
      .then((response) => {
        setPaymentMethods(response.body);
        setPaymentMethodId(paymentMethodId);
      }, toastMessage)
      .catch(toastMessage);
  };

  const handlePaymentMethodClick = () => {
    history.push({
      pathname: "/manage-payments",
      search: `?token=${token}&showBack=true`,
    });
  };

  const handleBookSpace = () => {
    setBookSpaceForm(true);
    if (selectingCreditCardIndex !== null && selectingCreditCardIndex !== -1) {
      setShowCreditCardModal(true);
      setShowAchBookSpaceModal(false);
      return;
    }
    if (selectingAchIndex !== null && selectingAchIndex !== -1) {
      setShowAchBookSpaceModal(true);
      setShowCreditCardModal(false);
      return;
    }
    if (!!cvv) {
      setShowCreditCardModal(true);
      setShowAchBookSpaceModal(false);
      return;
    }
  };

  const blurActiveInput = () => {
    if (document.activeElement && document.activeElement.tagName === "INPUT") {
      document.activeElement.blur();
    }
  };

  // console.log("selectingCreditCardIndex", selectingCreditCardIndex);
  // console.log("selectingAchIndex", selectingAchIndex);
  // console.log("setShowAchBookSpaceModal", showAchBookSpaceModal);
  // console.log("showCreditCardModal", showCreditCardModal);
  // console.log("selectedIndex1", selectedIndex1);
  // console.log("selectedIndex2", selectedIndex2);
  // console.log("windowLocation", window.location.href);

  const toastMessage = (error) => {
    const errorMessage = getErrorMessageForStandardResponse(
      error,
      "Failed to complete booking",
      true
    );
    if (errorMessage) toast.error(errorMessage);
    Busy.set(false);
  };

  const showCancelButton = () => {
    let iosNativeApp =
      webkit && webkit.messageHandlers && webkit.messageHandlers.nativeapp;
    let opener = window.opener;
    let androidNativeApp = Android && Android.onCancel;

    return iosNativeApp || opener || androidNativeApp;
  };

  const handleCancel = () => {
    postMessageAndClose(PostMessageStatus.CANCEL);
  };

  const postMessageAndClose = (status, data) => {
    // let openerParent = window.opener || window.parent;

    // opener will return the window that opened it
    // parent will return any sort if iframe or webview that opened it
    // if (openerParent) {
    //   openerParent.postMessage(
    //     {
    //       status: status,
    //       data: data,
    //       source: SOURCE,
    //     },
    //     openerParent.origin
    //   );

    //    //if there is no opener, window.close will not work, so don't call it
    //   if (window.opener) {
    //     setTimeout(() => {
    //       window.close();
    //     }, 2500);
    //   }
    //   history.push("");
    // }

    // custom ios postMessage listener logic
    webkit &&
      webkit.messageHandlers &&
      webkit.messageHandlers.nativeapp.postMessage({
        status: status,
        data: data,
        source: SOURCE,
      });

    if (data && Android && Android.onSuccess) {
      Android.onSuccess({
        status: status,
        data: data,
        source: SOURCE,
      });
    } else if (Android && Android.onCancel) {
      Android.onCancel({
        status: status,
        data: data,
        source: SOURCE,
      });
    }
  };

  const handleChecked = (e) => {
    setCheckedButtonPayment(e.target.value);
  };

  const handleSubmit = () => {
    Busy.set(true);

    ajaxRequest({
      url: "payment-method/createPaymentMethod",
      data: JSON.stringify({
        accountId: id,
        bankName: bankName,
        accountType: accountType,
        firstName: firstName,
        lastName: lastName,
        customerPhone: phoneNumber,
        addressLine1: address1,
        addressLine2: address2,
        zip: zip,
        city: city,
        state: state,
        country: "USA",
      }),
      contentType: "application/json",
      method: "POST",
      success: (res) => {
        Busy.set(false);
        toast.success("ACH account added successfully!");
        setAchPayment(true);
        setPaymentMethodId(res.id);
        // setDisabled(false);
        //setDisablePayment(true);
        setLoadPaymentMethod(!loadPaymentMethod);
        setCvv("");
        setCheckedButtonPayment("showPaymentDetailsACH");
        setFirstName("");
        setLastName("");
        setAddress1("");
        setAddress2("");
        setCity("");
        setZip("");
        setPhoneNumber("");
        setAccountType("");
        setState("");
        setErrorValidationMessagePhone("");
        setErrorValidationMessageZip("");
      },

      error: (jqXHR) => {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON
          ? jqXHR.responseJSON.message
            ? jqXHR.responseJSON.message.trim()
            : ""
          : "";
        toast.error(errorMessage);
      },
    });
  };

  const handleSubmitBookSpace = () => {
    // Busy.set(false);
    const paymentId = paymentMethods?.filter((ele) => ele.type === "ACH")[0].id;
    ajaxRequest({
      url: securityDepositRequiredState
        ? `zone/reservation/tsys/ach/${paymentId}/complete?reservationId=${reservationId}&buyerAccountId=${id}`
        : Number(fineAmount) > 0
        ? `fine-assessments/${paymentId}/pay-fine-ach/${reservationId}`
        : `zone/reservation/tsys/ach/${paymentId}/complete?reservationId=${reservationId}&buyerAccountId=${id}`,
      data: JSON.stringify(
        securityDepositRequiredState
          ? {
              bankName: bankName,
              routingNumber: routingNumber,
              accountNumber: accountNumber,
              accountType: accountType,
              checkNumber: checkNumber,
              securityDepositPayment: true,
              transactionAmount: (securityDepositAmount / 100).toFixed(2),
              token: token,
            }
          : Number(fineAmount) > 0
          ? {
              bankName: bankName,
              routingNumber: routingNumber,
              accountNumber: accountNumber,
              accountType: accountType,
              checkNumber: checkNumber,
              securityDepositPayment: false,
              transactionAmount: (fineAmount / 100).toFixed(2),
              token: token,
            }
          : {
              bankName: bankName,
              routingNumber: routingNumber,
              accountNumber: accountNumber,
              accountType: accountType,
              checkNumber: checkNumber,
              token: token,
            }
      ),
      contentType: "application/json",
      method: "POST",
      success: (res) => {
        Busy.set(false);
        const accountNum = accountNumber?.slice(-4);
        if (securityDepositRequiredState) {
          toast.success("Payment done successfully!");
          setSecurityDepositRequiredState(res?.securityDepositRequired);
          setsecurityDepositPaymentStatus(true);
          setdateInitiatedSecurityDeposit(res?.dateOfDepositProcessed);
          setsourceSecurityDeposit(
            `Bank Account ending in ${accountNum} - ${bankName}`
          );
          setsecurityDepositnickName(res?.nickName);
          setSecurityInvoiceNumber(res?.invoiceNumber);
          setSecurityDepositPaymentType("ACH Transfer");
          setSecurityDepositRequiredState(res?.securityDepositRequired);
          setBookSpaceForm(false);
          setCheckNumber("");
          setRoutingNumber("");
          setAccountNumber("");
        } else if (Number(fineAmount) > 0) {
          toast.success("Fine Paid Successfully!");
          setBookSpaceForm(false);
          console.log("res", res);
          history.push({
            pathname: "/payment-successful",
            state: {
              amount: res?.fineAmount,
              invoiceNumber: res?.invoiceNumber,
              transferType: "Bank Transfer",
              dateInitiated: res?.createdOn,
              nickName: res?.nickName,
              type: "fineType",
              source: `Bank Account ending in ${accountNum} - ${bankName}`,
            },
          });
        } else {
          toast.success("Space Booked Successfully!");
          setBookSpaceForm(false);
          history.push({
            pathname: "/payment-successful",
            state: {
              amount: res?.initialPaymentAmount,
              invoiceNumber: res?.invoiceNumber,
              transferType:
                res?.paymentType === "ACH"
                  ? "Bank Transfer"
                  : "Credit Card Transfer",
              dateInitiated: res?.approvedOn,
              nickName: res?.nickName,
              source: `Bank Account ending in ${accountNum} - ${bankName}`,
            },
          });
          postMessageAndClose(PostMessageStatus.SUCCESS);
        }
      },
      error: (jqXHR) => {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON
          ? jqXHR.responseJSON.message
            ? jqXHR.responseJSON.message.trim()
            : ""
          : "";
        toast.error(errorMessage);
        postMessageAndClose(PostMessageStatus.CANCEL);
      },
    });
    Busy.set(true);
  };

  const onInputChange = (e, setTxt) => {
    const value = e.target.value;
    const nonNumericValue = value.replace(/[0-9]/g, "");

    const re = /^[A-Za-z]+$/;
    if (
      !!nonNumericValue ||
      re.test(nonNumericValue) ||
      nonNumericValue === ""
    ) {
      setTxt(nonNumericValue);
    }
  };

  const onNumberChange = (e, setPhone) => {
    const regex = /[A-Za-z]/;
    if (regex.test(e.target.value)) return;
    if (e.target.name === "zip") {
      if (e.target.value?.trim().length !== 5) {
        setErrorValidationMessageZip("Zip code should be of 5 digit");
      } else {
        setErrorValidationMessageZip("");
      }
    }
    if (e.target.name === "phoneNumber") {
      if (e.target.value?.trim().length !== 10) {
        setErrorValidationMessagePhone("Phone number should be of 10 digit");
      } else {
        setErrorValidationMessagePhone("");
      }
    }
    const value = e.target.value?.trim();
    if (!isNaN(value)) setPhone(value);
  };

  const segregatedData = (data, val) => {
    const updateArr = data?.reduce((acc, item) => {
      const type = item.type;
      acc[type] = (acc[type] || []).concat(item);
      return acc;
    }, []);
    return updateArr[val];
  };
  // console.log("accountType", accountType);

  const handleCancelAch = () => {
    setCheckedButtonPayment("showPaymentDetails");
    setFirstName("");
    setLastName("");
    setBankName("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setZip("");
    setPhoneNumber("");
    setAccountType("");
    setState("");
    setErrorValidationMessagePhone("");
    setErrorValidationMessageZip("");
  };

  const handlePaySecurityDeposit = () => {
    // history.push({
    //   pathname: "/payment-successful",
    //   state: {
    //     amount: 1000,
    //     invoiceNumber: 123,
    //     type: "Security Deposit",
    //     transferType: "Bank Transfer",
    //     //dateInitiated: res?.approvedOn,
    //     tokenValue: tokenValue,
    //     reservationIdValue: reservationIdValue,
    //     dateInitiated: "04/22/2024",
    //     nickName: "",
    //     source: `Bank Account ending in 4356 - SBI`,
    //   },
    // });
  };
  const handleCancelAchBookSpace = () => {
    setBookSpaceForm(false);
    setCheckNumber("");
    setRoutingNumber("");
    setAccountNumber("");
    postMessageAndClose(PostMessageStatus.CANCEL);
  };

  // console.log("bankName", bankName);
  // console.log("showCreditCardModal", showCreditCardModal);
  // console.log("showAchBookSpaceModal", showAchBookSpaceModal);
  // console.log("firstName", firstName);
  // console.log("state", state);
  // console.log("accountType", accountType);
  // console.log("securityDepositPaymentStatus", securityDepositPaymentStatus);
  // console.log("securityDepositRequiredState", securityDepositRequired);
  // console.log("securityDepositAmount", securityDepositAmount);
  // console.log("errorMessage", errorMessage);
  console.log("fineByIdDetails", fineByIdDetails);

  return (
    <>
      {" "}
      {securityDepositPaymentStatus ? (
        <SecurityDepositSuccessfull
          dateInitiatedSecurityDeposit={dateInitiatedSecurityDeposit}
          setsecurityDepositPaymentStatus={setsecurityDepositPaymentStatus}
          sourceSecurityDeposit={sourceSecurityDeposit}
          securityDepositnickName={securityDepositnickName}
          securityDepositPaymentType={securityDepositPaymentType}
          securityDepositAmount={(securityDepositAmount / 100).toFixed(2)}
          securityInvoiceNumber={securityInvoiceNumber}
        />
      ) : (
        <>
          {!errorMessage && (
            <ReportBackgroundContainer>
              {securityDepositRequiredState ? (
                <>
                  {" "}
                  <ReportHeader parentMenu={"Checkout"} type="securityDeposit">
                    Security Deposit
                  </ReportHeader>
                  <ReportTitle>Cost Summary</ReportTitle>
                  <ReportDataRow>
                    <ReportData label={"Initial Security Deposit Amount"}>
                      $ {Number(securityDepositAmountState)}
                    </ReportData>
                  </ReportDataRow>
                </>
              ) : (
                <>
                  <ReportHeader parentMenu={"Checkout"}>
                    {Number(fineAmount) > 0
                      ? "Fine Payment"
                      : "Reservation Checkout"}
                  </ReportHeader>
                  <ReportTitle>
                    {Number(fineAmount) > 0
                      ? "Fine Payment Info"
                      : "Reservation Info"}
                  </ReportTitle>
                  {Number(fineAmount) > 0 ? (
                    <div style={{ marginBottom: "30px" }}>
                      <FineCheckout fineByIdDetails={fineByIdDetails} />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <ReservationCheckout reservation={reservation} />
                      <ReportTitle>Zone Info</ReportTitle>
                      <ZoneCheckout zone={zone} />
                      <ReportTitle>Cost Summary</ReportTitle>
                      <DisplayChargeAmount
                        reservationId={reservationId}
                        paymentMethodId={paymentMethodId}
                      />
                    </>
                  )}
                </>
              )}
              {!disablePayment && (
                <>
                  <div className="inputLabel">
                    <ReportTitle>Add Payment Method</ReportTitle>
                    <span>
                      <input
                        type="checkbox"
                        id="paywithcreditcard"
                        name="paywithcreditcard"
                        value="creditcard"
                        onClick={handleChecked}
                        checked={checkedButtonPayment === "creditcard"}
                        disabled={
                          segregatedData(paymentMethods, "CARD")?.length >= 3
                        }
                        style={{
                          marginRight: "12px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                        // disabled={true}
                      />
                      <label
                        htmlFor="paywithcreditcard"
                        style={{
                          marginTop: "4px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        Add Credit Card
                      </label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        id="paywithach"
                        name="paywithach"
                        value="ach"
                        onClick={handleChecked}
                        checked={checkedButtonPayment === "ach"}
                        disabled={paymentMethods?.some(
                          (ele) => ele?.type === "ACH"
                        )}
                        style={{
                          marginLeft: "24px",
                          marginRight: "10px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                        // style={
                        //   checkedButtonPayment === "showPaymentDetailsACH"
                        //     ? {
                        //         marginLeft: "24px",
                        //         marginRight: "10px",
                        //         cursor: "pointer",
                        //         accentColor: "#F79019",
                        //         pointerEvents: "none",
                        //         appearance: "none",
                        //         backgroundColor: "#F79019",
                        //         borderRadius: "50%",
                        //         height: "13px",
                        //         width: "13px",
                        //         marginTop: "7px",
                        //       }
                        //     : {
                        //         marginLeft: "24px",
                        //         marginRight: "10px",
                        //         cursor: "pointer",
                        //       }
                        // }
                      />
                      <label
                        for="paywithach"
                        style={
                          checkedButtonPayment === "submitted"
                            ? {
                                marginTop: "4px",
                                pointerEvents: "none",
                                display: "inline-block",
                                verticalAlign: "middle",
                              }
                            : {
                                marginTop: "4px",
                                display: "inline-block",
                                verticalAlign: "middle",
                              }
                        }
                      >
                        Add ACH
                      </label>
                    </span>
                  </div>
                  {checkedButtonPayment === "creditcard" && (
                    <CreditCardModalPopUp
                      setCheckedButtonPayment={setCheckedButtonPayment}
                      setLoadPaymentMethod={setLoadPaymentMethod}
                      loadPaymentMethod={loadPaymentMethod}
                      id={id}
                      setCvv={setCvv}
                      cvv={cvv}
                      setAchPayment={setAchPayment}
                    />
                  )}

                  {checkedButtonPayment === "ach" && (
                    <Modal className="app-modal app-modal-in">
                      <div id="ssCreateEditLocation">
                        <div className="modal-content ">
                          <h1
                            className="content-header-title"
                            style={{ marginTop: "-27px" }}
                          >
                            ACH Details
                          </h1>
                          <form
                            className="ss-form ss-block"
                            style={{ padding: "30px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="fName">FIRST NAME</label>
                                <input
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  value={firstName}
                                  ref={firstNameRef}
                                  onChange={(e) =>
                                    onInputChange(e, setFirstName)
                                  }
                                  placeholder="Enter your first name"
                                />
                              </fieldset>
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="lName">LAST NAME</label>
                                <input
                                  type="text"
                                  id="lastname"
                                  name="lastname"
                                  value={lastName}
                                  ref={lastNameRef}
                                  onChange={(e) =>
                                    onInputChange(e, setLastName)
                                  }
                                  placeholder="Enter your last name"
                                />
                              </fieldset>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="bankName">BANK NAME</label>
                                <input
                                  type="text"
                                  id="bankName"
                                  name="bankName"
                                  ref={bankNameRef}
                                  value={bankName}
                                  onChange={(e) =>
                                    onInputChange(e, setBankName)
                                  }
                                  placeholder="Enter bank name"
                                />
                              </fieldset>

                              <fieldset className="ss-stand-alone  hs-field">
                                <label for="accountType">ACCOUNT TYPE </label>

                                <Dropdown
                                  options={[
                                    { label: "CHECKING", value: "CHECKING" },
                                    { label: "SAVING", value: "SAVING" },
                                    { label: "CREDIT", value: "CREDIT" },
                                  ]}
                                  onChange={(ele) => setAccountType(ele.value)}
                                  onFocus={blurActiveInput}
                                  value={accountType}
                                  placeholder="Please select"
                                  controlClassName="myControlClassName"
                                  arrowClassName="myArrowClassName"
                                />
                              </fieldset>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="address">ADDRESS LINE 1</label>
                                <input
                                  type="text"
                                  id="address1"
                                  name="address1"
                                  ref={address1Ref}
                                  value={address1}
                                  onChange={(e) => setAddress1(e.target.value)}
                                  placeholder="Enter adddress line 1"
                                />
                              </fieldset>
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="address2">ADDRESS LINE 2</label>
                                <input
                                  type="text"
                                  id="address2"
                                  name="address2"
                                  value={address2}
                                  ref={address2Ref}
                                  onChange={(e) => setAddress2(e.target.value)}
                                  placeholder="Enter adddress line 2"
                                />
                              </fieldset>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="city">CITY</label>
                                <input
                                  type="text"
                                  id="city"
                                  name="city"
                                  value={city}
                                  ref={cityRef}
                                  onChange={(e) => onInputChange(e, setCity)}
                                  placeholder="Enter city"
                                />
                              </fieldset>

                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="state">STATE</label>

                                <Dropdown
                                  options={stateConstant}
                                  onChange={(ele) => setState(ele.value)}
                                  onFocus={blurActiveInput}
                                  value={state}
                                  placeholder="Please select"
                                  controlClassName="myControlClassName"
                                  arrowClassName="myArrowClassName"
                                />
                              </fieldset>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <fieldset className="ss-stand-alone  hs-field country">
                                <label htmlFor="zip">ZIP CODE</label>
                                <input
                                  type="text"
                                  id="zip"
                                  name="zip"
                                  maxLength={5}
                                  ref={zipRef}
                                  style={{ width: "100%" }}
                                  value={zip}
                                  onChange={(e) => onNumberChange(e, setZip)}
                                  placeholder="Enter zip code"
                                />
                              </fieldset>
                              <span className="ss-error-form">
                                {errorValidationMessageZip}
                              </span>
                              <fieldset
                                className="ss-stand-alone  hs-field"
                                style={{ backgroundColor: "#eeeeee" }}
                              >
                                <label htmlFor="country">COUNTRY</label>
                                <input
                                  type="text"
                                  id="country"
                                  name="country"
                                  value="USA"
                                  readOnly
                                  style={{ cursor: "not-allowed" }}
                                />
                              </fieldset>
                            </div>
                            <div>
                              <fieldset
                                className="ss-stand-alone  hs-field"
                                style={{ marginBottom: "16px" }}
                              >
                                <label htmlFor="phoneNumber">
                                  PHONE NUMBER
                                </label>
                                <input
                                  type="tel"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  style={{ width: "100%" }}
                                  value={phoneNumber}
                                  ref={phoneNumberRef}
                                  maxLength={10}
                                  required
                                  onChange={(e) =>
                                    onNumberChange(e, setPhoneNumber)
                                  }
                                  placeholder="Enter phone number"
                                />
                              </fieldset>
                              {!!errorValidationMessagePhone ? (
                                <span className="ss-error-formPhone">
                                  {errorValidationMessagePhone}
                                </span>
                              ) : (
                                <div style={{ marginBottom: "10px" }}></div>
                              )}
                            </div>

                            <div
                              className="buttonDivSubmit"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <button
                                type="button"
                                className="ss-button-secondary"
                                style={{ width: "200px" }}
                                onClick={handleCancelAch}
                              >
                                Cancel
                              </button>

                              <button
                                type="button"
                                className="ss-button-primary"
                                style={
                                  !(
                                    !!firstName !== "" &&
                                    !!lastName &&
                                    phoneNumber?.length === 10 &&
                                    !!address1 &&
                                    !!address2 &&
                                    !!city &&
                                    zip?.length === 5 &&
                                    state !== "" &&
                                    accountType !== "" &&
                                    !!bankName
                                  )
                                    ? { width: "200px", opacity: "0.5" }
                                    : { width: "200px" }
                                }
                                onClick={handleSubmit}
                                disabled={
                                  !(
                                    !!firstName &&
                                    !!lastName &&
                                    phoneNumber?.length === 10 &&
                                    !!address1 &&
                                    !!address2 &&
                                    !!city &&
                                    zip?.length === 5 &&
                                    state !== "" &&
                                    accountType !== "" &&
                                    !!bankName
                                  )
                                }
                              >
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Modal>
                  )}
                </>
              )}
              {paymentMethods?.length > 0 && (
                <DisplayAddedPaymentMethod
                  checkedButtonPayment={checkedButtonPayment}
                  paymentMethods={paymentMethods}
                  setLoadPaymentMethod={setLoadPaymentMethod}
                  loadPaymentMethod={loadPaymentMethod}
                  cvv={cvv}
                  setSelectingAchIndex={setSelectingAchIndex}
                  setSelectingCreditCardIndex={setSelectingCreditCardIndex}
                  achPayment={achPayment}
                  setSelectedIndex1={setSelectedIndex1}
                  setSelectedIndex2={setSelectedIndex2}
                  selectedIndex2={selectedIndex2}
                  selectedIndex1={selectedIndex1}
                  setBankName={setBankName}
                  setAchPayment={setAchPayment}
                />
              )}

              {bookSpaceForm && showAchBookSpaceModal && (
                <Modal className="app-modal app-modal-in">
                  <div id="ssCreateEditLocation">
                    <div className="modal-content ">
                      <h1
                        className="content-header-title"
                        style={{ marginTop: "-27px" }}
                      >
                        BANK Details
                      </h1>
                      <form
                        className="ss-form ss-block"
                        style={{ padding: "30px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <fieldset
                            className="ss-stand-alone  hs-field"
                            style={{ backgroundColor: "#eeeeee" }}
                          >
                            <label htmlFor="bankName">BANK NAME</label>
                            <input
                              type="text"
                              id="bankNameBookSpace"
                              name="bankNameBookSpace"
                              value={bankName}
                              // onChange={(e) => onInputChange(e, setBankName)}
                              placeholder="Enter bank name"
                              readonly
                            />
                          </fieldset>
                          <fieldset className="ss-stand-alone  hs-field">
                            <label htmlFor="fName">ROUTING NUMBER</label>
                            <input
                              type="text"
                              id="routingNumber"
                              name="routingNumber"
                              value={routingNumber}
                              maxLength={9}
                              onChange={(e) =>
                                onNumberChange(e, setRoutingNumber)
                              }
                              placeholder="Enter your routing number"
                            />
                          </fieldset>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <fieldset className="ss-stand-alone  hs-field">
                            <label htmlFor="fName">ACCOUNT NUMBER</label>
                            <input
                              type="text"
                              id="accountNumber"
                              name="accountNumber"
                              value={accountNumber}
                              maxLength={12}
                              onChange={(e) =>
                                onNumberChange(e, setAccountNumber)
                              }
                              placeholder="Enter your account number"
                            />
                          </fieldset>
                          <fieldset className="ss-stand-alone  hs-field">
                            <label htmlFor="fName">CHECK NUMBER</label>
                            <input
                              type="text"
                              id="checkNumber"
                              name="checkNumber"
                              value={checkNumber}
                              maxLength={19}
                              onChange={(e) =>
                                onNumberChange(e, setCheckNumber)
                              }
                              placeholder="Enter your check number"
                            />
                          </fieldset>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            type="button"
                            className="ss-button-secondary"
                            style={{ width: "200px", marginLeft: "3px" }}
                            onClick={handleCancelAchBookSpace}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ss-button-primary"
                            style={
                              !(
                                !!bankName &&
                                !!routingNumber &&
                                !!accountNumber &&
                                !!checkNumber
                              )
                                ? {
                                    opacity: "0.5",
                                    width: "200px",
                                    paddingLeft: "21px",
                                  }
                                : { width: "200px", paddingLeft: "24px" }
                            }
                            onClick={handleSubmitBookSpace}
                            //onClick={handlePaySecurityDeposit}
                            disabled={
                              !(
                                !!bankName &&
                                !!routingNumber &&
                                !!accountNumber &&
                                !!checkNumber
                              )
                            }
                          >
                            Process Payment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal>
              )}

              {bookSpaceForm && showCreditCardModal && (
                <CrediCardDisplayPopUp
                  paymentMethods={paymentMethods}
                  setBookSpaceForm={setBookSpaceForm}
                  selectingCreditCardIndex={selectingCreditCardIndex}
                  setSelectingAchIndex={setSelectingAchIndex}
                  setSelectingCreditCardIndex={setSelectingCreditCardIndex}
                  reservationId={reservationId}
                  setSecurityDepositRequiredState={
                    setSecurityDepositRequiredState
                  }
                  token={token}
                  securityDepositAmountState={securityDepositAmountState}
                  id={id}
                  securityDepositRequiredState={securityDepositRequiredState}
                  setsecurityDepositPaymentStatus={
                    setsecurityDepositPaymentStatus
                  }
                  setdateInitiatedSecurityDeposit={
                    setdateInitiatedSecurityDeposit
                  }
                  setsourceSecurityDeposit={setsourceSecurityDeposit}
                  setSecurityInvoiceNumber={setSecurityInvoiceNumber}
                  setsecurityDepositnickName={setsecurityDepositnickName}
                  setSecurityDepositPaymentType={setSecurityDepositPaymentType}
                />
              )}
              {securityDepositRequiredState && (
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <input
                    type="checkbox"
                    className="securityCheckBox"
                    onClick={() =>
                      setShowSecurityDepositPayment(!showSecurityDepositPayment)
                    }
                  />{" "}
                  I <b>acknowledge</b> and <b>agree</b> to pay the required
                  security deposit before booking the space
                </div>
              )}

              {securityDepositRequiredState ? (
                <>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <SolidButton
                      id={"ss-book-space-button"}
                      disabled={
                        !showSecurityDepositPayment ||
                        !(paymentMethods.length > 0)
                      }
                      label={"Pay Now"}
                      onClick={handleBookSpace}
                      // onClick={handlePaySecurityDeposit}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div style={{ marginTop: "30px" }}>
                    <SolidButton
                      id={"ss-book-space-button"}
                      disabled={disabled}
                      label={Number(fineAmount) > 0 ? "Pay Now" : "Book Space"}
                      onClick={handleBookSpace}
                    />
                  </div>
                </>
              )}

              <br />

              <div style={{ marginTop: "60px" }}></div>
            </ReportBackgroundContainer>
          )}
          {
            <div>
              {expirePopUp ? (
                <span>
                  {/* <ErrorRounded /> */}
                  {/* {errorMessage} */}
                  <Modal className={"modal-form alert-message"}>
                    <div
                      style={{
                        width: "80%",
                        marginTop: "18%",
                        marginLeft: "7%",
                      }}
                    >
                      <div className="modal-content">
                        <div className={"modal-header d-flex"}>
                          <h5>Payment Link Expired!</h5>
                        </div>
                        <div className="modal-body">
                          <p>
                            Your payment link has expired.Please contact the
                            administrator to resend the checkout email,or try
                            making the reservation again!
                          </p>
                        </div>
                        <div
                          className="modal-footer"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            type="button"
                            className="ss-button-primary-modal-form-checkout"
                            onClick={() =>
                              (window.location.href =
                                "https://oaklandportalstage.portofoakland.com/#/")
                            }
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </span>
              ) : (
                <span>
                  {/* <ErrorRounded /> */}
                  {/* {errorMessage} */}
                  {!!errorMessage && (
                    <Modal className={"modal-form alert-message"}>
                      <div
                        style={{
                          width: "80%",
                          marginTop: "18%",
                          marginLeft: "7%",
                        }}
                      >
                        <div className="modal-content">
                          <div className={"modal-header d-flex"}>
                            <h5 style={{ color: "red" }}> {errorMessage}</h5>
                          </div>
                          <div
                            className="modal-footer"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              type="button"
                              className="ss-button-primary-modal-form-checkout"
                              onClick={() =>
                                (window.location.href =
                                  "https://oaklandportalstage.portofoakland.com/#/")
                              }
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  )}
                </span>
              )}
            </div>
          }
        </>
      )}
    </>
  );
};

export default Checkout;
