import React from "react";
import ReportData from "../report/ReportData";
import {
  formatBool,
  formatEquipmentLength,
} from "../../util/booking/bookingUtil";
import ReportDataRow from "../report/ReportDataRow";

const ZoneRevenueItem = (props) => {
  const { zone } = props;

  return (
    <ReportDataRow key={zone.zoneId}>
      <ReportData label={"Location Name"}>
        {zone?.locationName || "-"}
      </ReportData>
      <ReportData label={"Zone Name"}>{zone?.zoneName || "-"}</ReportData>

      <ReportData label={"Power Accessible"}>
        {formatBool(zone.powerAccessible)}
      </ReportData>
      <ReportData label={"Equipment Length"}>
        {!!formatEquipmentLength(zone.equipmentLength)
          ? formatEquipmentLength(zone.equipmentLength)
          : "-"}
      </ReportData>

      <ReportData label={"Total Spaces"}>{zone?.totalSpaces}</ReportData>

      <ReportData label={"Total Revenue"}>
        {zone?.totalRevenue ? `$${(zone.totalRevenue / 100).toFixed(2)}` : "-"}
      </ReportData>
    </ReportDataRow>
  );
};

export default ZoneRevenueItem;
