import React, { useEffect, useState } from "react";
import InvoiceList from "./InvoiceList";
import { requestInvoices } from "./requests/invoice-requests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { toast } from "react-toastify";
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from "../../util/report/ReportUtils";
import { searchRecordsByKeywordWaitListAvailability } from "../../util/SearchUtils";
import _ from "underscore";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import ReportHeader from "../report/ReportHeader";
import Filter from "../../views/bookings/Filter";
import GroupAndSort from "../../views/bookings/GroupAndSort";
import ExportCSVButton from "../report/export/ExportCSVButton";
import ReportFormDateInput from "../report/ReportFormDateInput";
import ReportFormContainer from "../report/ReportFormContainer";
import {
  compareObjectsAscending,
  compareObjectsDescending,
  DateFormats,
} from "../constants/securspace-constants";
import moment from "moment";
import ReportEmpty from "../report/ReportEmpty";
import { extractDay } from "../constants/securspace-constants";
import Busy from "../Busy";

const SupplierInvoicesReport = (props) => {
  const reportFields = [
    { label: "Customer", name: "buyerCompanyName" },
    { label: "Type", name: "transactionType" },
    { label: "Status", name: "status" },
    { label: "Invoice Created", name: "createdOn" },
    { label: "Payment Initiated", name: "paymentCreatedOn" },
    { label: "Payment Completed", name: "paymentCompletedOn" },
    { label: "Invoice Number", name: "transactionNumber" },
    { label: "Booking Number", name: "bookingNumber" },
  ];

  const { account } = props;

  const defaultStartDate = moment()
    .subtract(30, "days")
    .format(DateFormats.DAY);
  const defaultEndDate = moment().format(DateFormats.DAY);

  const [invoices, setInvoices] = useState([]);
  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION_ASC);
  const [groupDirection, setGroupDirection] = useState(SORT_DIRECTION_ASC);
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [sortDataAfterApiCall, SetSortDataAfterApiCall] = useState(false);
  useEffect(() => {
    let requestDates = {
      startDate: startDate,
      endDate: endDate,
    };
    Busy.set(true);
    requestInvoices(account.id, requestDates)
      .then(
        (response) => {
          const data = response.body;
          setInvoices(data);
          setFilteredData(data);
          SetSortDataAfterApiCall(!sortDataAfterApiCall);
          Busy.set(false);
        },
        (error) => {
          toast.error(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        }
      )
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
  }, [account, startDate, endDate]);
  useEffect(() => {}, [filteredData?.length, sortBy?.name]);

  useEffect(() => {
    sortData(sortBy);
  }, [
    startDate,
    endDate,
    sortBy?.name,
    sortDirection,
    filteredData?.length,
    sortDataAfterApiCall,
  ]);

  const handleStartDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(startDate);

    if (!(day1 === day2)) {
      setStartDate(date);
    }
  };

  const handleEndDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(endDate);

    if (!(day1 === day2)) {
      setEndDate(date);
    }
  };

  const searchChangeHandler = (event) => {
    const value = event.target.value;
    setSearchBox(value);
    setFilteredData(
      searchRecordsByKeywordWaitListAvailability(value, invoices)
    );
  };

  const groupByChangeHandler = (event) => {
    const value = event.target.value;
    setGroupBy(value);
    groupData(value);
  };

  const groupData = (value) => {
    const groupedData = _.groupBy(filteredData, value);
    const toArray = _.toArray(groupedData);
    const flattened = _.flatten(toArray);
    setFilteredData(flattened);
  };

  const sortByChangeHandler = (event) => {
    const value = event.target.value;
    setSortBy(value);
    //sortData(value);
  };

  const sortData = (value) => {
    let tempData = [...filteredData];

    if (tempData) {
      if (value?.name) {
        if (sortDirection === SORT_DIRECTION_ASC) {
          tempData = tempData.sort((a, b) => {
            return compareObjectsAscending(a, b, value);
          });
        }
        if (sortDirection === SORT_DIRECTION_DESC) {
          tempData = tempData.sort((a, b) => {
            return compareObjectsDescending(a, b, sortBy);
          });
        }
      }
    }

    setFilteredData(tempData);
  };

  const sortDirectionChangeHandler = (value) => {
    setSortDirection(
      sortDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
  };

  const groupDirectionChangeHandler = () => {
    setGroupDirection(
      groupDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
    groupData(groupBy);
  };

  return (
    <ReportBackgroundContainer>
      <ReportHeader>Invoices</ReportHeader>
      <div style={{ width: "49%" }}>
        <ReportFormContainer>
          <ReportFormDateInput
            label={"START DATE:"}
            date={startDate}
            onDateChange={handleStartDate}
          />
          <ReportFormDateInput
            label={"END DATE:"}
            date={endDate}
            onDateChange={handleEndDate}
          />
        </ReportFormContainer>
      </div>
      {!!invoices && invoices.length > 0 ? (
        <>
          <Filter
            searchBox={searchBox}
            searchChangeHandler={searchChangeHandler}
            placeHolder="Type any field to filter supplier invoices result"
          />
          {filteredData.length > 0 && (
            <>
              <GroupAndSort
                groupByOptions={reportFields}
                groupByChangeHandler={groupByChangeHandler}
                groupBy={groupBy}
                groupDirection={groupDirection}
                groupDirectionChangeHandler={groupDirectionChangeHandler}
                sortBy={sortBy}
                sortByChangeHandler={sortByChangeHandler}
                sortDirection={sortDirection}
                sortDirectionChangeHandler={sortDirectionChangeHandler}
                asc={SORT_DIRECTION_ASC}
                desc={SORT_DIRECTION_DESC}
              />
              <ExportCSVButton
                data={filteredData}
                reportName={"Invoices Report"}
              />
              <br />
              <InvoiceList invoices={filteredData} />
            </>
          )}
          {filteredData.length === 0 && <ReportEmpty />}
        </>
      ) : (
        <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default SupplierInvoicesReport;
