import React from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import {
  formatBool,
  formatDate,
  formatEquipmentLength,
} from "../../util/booking/bookingUtil";

const WaitListItems = (props) => {
  const { waitListEntry } = props;

  const formatFrequency = (waitListEntry) => {
    return waitListEntry === "RECURRING" ? "Recurring " : "One-Time";
  };

  return (
    <ReportDataRow>
      <ReportData label={"Company Name"}>
        {waitListEntry?.companyName || "-"}
      </ReportData>
      <ReportData label={"Number of Spaces"}>
        {waitListEntry?.numberOfSpaces || "-"}
      </ReportData>
      <ReportData label={"Start Date"}>
        {waitListEntry?.startDate ? formatDate(waitListEntry.startDate) : "-"}
      </ReportData>
      <ReportData label={"End Date"}>
        {waitListEntry?.endDate ? formatDate(waitListEntry?.endDate) : "-"}
      </ReportData>
      <ReportData label={"Frequency"}>
        {waitListEntry?.frequency ? waitListEntry?.frequency : "-"}
      </ReportData>
      <ReportData label={"Power Accessible"}>
        {formatBool(waitListEntry.powerAccessible)}
      </ReportData>
      <ReportData label={"Equipment Length"}>
        {waitListEntry?.equipmentLength
          ? formatEquipmentLength(waitListEntry?.equipmentLength)
          : "-"}
      </ReportData>
      <ReportData label={"Status"}>{waitListEntry?.status || "-"}</ReportData>
      <ReportData label={"Status Last Updated Date"}>
        {waitListEntry?.statusUpdatedDate
          ? formatDate(waitListEntry?.statusUpdatedDate)
          : ""}
      </ReportData>
      <ReportData label={"Days On WaitList"}>
        {waitListEntry?.durationOnWaitList || "-"}
      </ReportData>
      <ReportData label={"Created On"}>
        {waitListEntry?.creationDate
          ? formatDate(waitListEntry.creationDate)
          : "-"}
      </ReportData>
      <ReportData label={"Last Updated By"}>
        {waitListEntry?.lastModifiedBy || "-"}
      </ReportData>
      <ReportData label={"Created By"}>
        {waitListEntry?.createdBy || "-"}
      </ReportData>
    </ReportDataRow>
  );
};

export default WaitListItems;
