import React, { useState, useRef } from "react";
import Select from "../Select";
import DropGallery from "../DropGallery";
import Busy from "../Busy";
import { ajaxRequest } from "../../ajax";
import { toast } from "react-toastify";
import { isImageFile } from "../constants/securspace-constants";

const EditSecurityDepositForm = ({
  securityDepositParticularRecordDetails,
  setShowModal,
  setLoadGetAPi,
}) => {
  const GALLERY_BUCKET = "gallery";
  const [dropfile, setDropFile] = useState(null);
  const [refundStatusName, setRefundStatusName] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [notes, setNotes] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modeOfPaymentNumber, setModeOfPaymentNumber] = useState(null);
  const [securityDepositFileName, setSecurityDepositFileName] = useState(); // State for old file name
  //const dropfile = useRef(null); // Use ref for dropfile

  const updateDropzone = (dropzone) => {
    setDropFile(dropzone);
  };

  const handleFileUpload = () => {
    let allUploadFiles = [];

    if (!!dropfile && dropfile?.files?.length > 0) {
      for (let i = 0; i < dropfile.files.length; i++) {
        let file = dropfile.files[i];

        // New file
        allUploadFiles.push({
          files: [file],
          fileNameProperty: "securityDepositFileName",
          folder: GALLERY_BUCKET,
          isImageFile: true,
        });
      }
    }

    if (validateFiles(allUploadFiles)) {
      // Busy.set(true); // Assuming Busy is globally accessible or a prop
      uploadFilesAndSubmitForm(allUploadFiles);
    }
  };

  const validateFiles = (allUploadFiles) => {
    for (let i = 0; i < allUploadFiles.length; i++) {
      let fileToUpload = allUploadFiles[i];
      let file = fileToUpload.files ? fileToUpload.files[0] : "";

      // Validate file size
      if (file.size > 20480000) {
        setErrorMessage("Selected file is too large. Max file size is 20 MB.");
        return false;
      }

      // Check if it's an image file
      if (fileToUpload.isImageFile) {
        if (file && !isImageFile(file)) {
          setErrorMessage(
            "File selected for listing image is not an image file."
          );
          return false;
        }
      }
    }
    // If all validations pass
    return true;
  };

  const uploadFilesAndSubmitForm = (filesToUpload) => {
    if (!filesToUpload || filesToUpload.length === 0) {
      return;
    }

    let fileToUpload = filesToUpload.shift();
    let file = fileToUpload.files ? fileToUpload.files[0] : "";

    if (file && file.name) {
      uploadFile(
        fileToUpload.folder,
        fileToUpload.oldFileName,
        file,
        (uploadedFileName, textStatus, jqXHR) => {
          // Assuming dynamic state setting (can be useState or useReducer)
          // You might need to adjust how you're setting the file name property.
          uploadFilesAndSubmitForm(filesToUpload); // Continue uploading remaining files
        }
      );
    }
  };

  const uploadFile = (folder, oldFileName, file, onSuccess) => {
    let data = new FormData();
    data.append("inputStream", file.dataURL);
    let profileFileName = securityDepositParticularRecordDetails?.id;
    setSecurityDepositFileName(file.name);

    let url = `file/upload/gallery?name=${file.name}&inventoryId=${profileFileName}&contentType=${file.type}`;

    ajaxRequest({
      url: url,
      type: "POST",
      data: data,
      cache: false,
      processData: false, // Don't process the files
      contentType: false, // Set content type to false as jQuery will tell the server its a query string request
      success: function (data, textStatus, jqXHR) {
        onSuccess(data, textStatus, jqXHR);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Busy.set(false);
        console.log("error", errorThrown);
      },
    });
  };

  const handleRefundStatus = (event) => {
    setRefundStatusName(event.target.value);
  };
  const handleModeOfPayment = (event) => {
    setModeOfPayment(event.target.value);
  };
  const handleSuccessSecurity = () => {
    toast.success("Refund status updated successfully!");
    Busy.set(false);
    setLoadGetAPi(true);
    setShowModal(false);
  };
  const handleFailed = (err) => {
    console.log("err", err);
    toast.error(err?.responseJSON?.message);
    Busy.set(false);
  };
  const getRefundPaymentType = (val) => {
    let refundType = "";
    switch (val) {
      case "CHEQUE":
        refundType = "CHEQUE_NUMBER";
        break;
      case "DEMAND DRAFT":
        refundType = "DD";
        break;
      case "ONLINE PAYMENT":
        refundType = "ONLINE_PAYMENT";
        break;
      default:
        refundType = null;
    }
    return refundType;
  };
  const getStatusType = (val) => {
    let statusType = "";
    switch (val) {
      case "Requested":
        statusType = "REQUESTED";
        break;
      case "In Progress":
        statusType = "IN_PROGRESS";
        break;
      case "Rejected":
        statusType = "REJECTED";
        break;
      case "Completed":
        statusType = "COMPLETED";
        break;
      case "Cancelled":
        statusType = "CANCELLED";
        break;
    }
    return statusType;
  };
  const handleSubmit = () => {
    handleFileUpload();
    Busy.set(true);
    const payloadData = {
      id: securityDepositParticularRecordDetails?.id,
      refundPaymentType: getRefundPaymentType(modeOfPayment),
      refundReturnRequestNumber: modeOfPaymentNumber,
      refundStatus: getStatusType(refundStatusName),
      notes: notes,
      imageUrl: securityDepositFileName,
    };
    // Busy.set(false);

    ajaxRequest({
      url: "security-deposit",
      data: JSON.stringify(payloadData),
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: handleSuccessSecurity,
      error: handleFailed,
    });
  };
  // console.log(
  //   "securityDepositParticularRecordDetails",
  //   securityDepositParticularRecordDetails
  // );

  return (
    <>
      <form className="ss-form ss-block">
        <div style={{ paddingRight: "44px", paddingLeft: "48px" }}>
          <div className="checkin-selector">
            <label>REFUND STATUS</label>

            <Select
              id="refundStatus"
              name="refundStatus"
              className="ss-book-space-form-asset-type"
              optionsWidth="300px"
              handleChange={handleRefundStatus}
              selectedOption={refundStatusName}
              placeholder="Please select"
              options={
                securityDepositParticularRecordDetails?.refundStatus === "NA" ||
                securityDepositParticularRecordDetails?.refundStatus ===
                  "REJECTED" ||
                securityDepositParticularRecordDetails?.refundStatus ===
                  "CANCELLED"
                  ? ["Requested"]
                  : securityDepositParticularRecordDetails?.refundStatus ===
                    "REQUESTED"
                  ? ["In Progress"]
                  : ["Cancelled", "Completed", "Rejected"]
              }
            />
          </div>
          {(refundStatusName === "Cancelled" ||
            refundStatusName === "Rejected") && (
            <fieldset
              className="ss-middle"
              style={{ borderRadius: "5px", border: "solid 1px" }}
            >
              <label htmlFor="notes">REMARKS OR NOTES</label>
              <textarea
                id="notes"
                name="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Enter notes regarding the refund status."
              />
            </fieldset>
          )}
          {refundStatusName === "Completed" && !!refundStatusName && (
            <>
              {" "}
              <div className="checkin-selector">
                <label>MODE OF REFUND PAYMENT</label>

                <Select
                  id="modeOfPayment"
                  name="modeOfPayment"
                  className="ss-book-space-form-asset-type"
                  optionsWidth="300px"
                  handleChange={handleModeOfPayment}
                  selectedOption={modeOfPayment}
                  placeholder="Please select"
                  options={["CHEQUE", "DEMAND DRAFT", "ONLINE PAYMENT"]}
                />
              </div>
              {!!modeOfPayment && (
                <>
                  <fieldset
                    className="ss-middle"
                    style={{ borderRadius: "5px", border: "solid 1px #e1e1e1" }}
                  >
                    <label htmlFor="modeOfPaymentNumber">{modeOfPayment}</label>
                    <input
                      name="modeOfPaymentNumber"
                      id="modeOfPaymentNumber"
                      value={modeOfPaymentNumber}
                      onChange={(e) => setModeOfPaymentNumber(e.target.value)}
                      placeholder={`Enter ${modeOfPayment} number`}
                    />
                  </fieldset>
                  <p style={{ marginTop: "10px" }}>
                    Click below to add image of {modeOfPayment}
                  </p>
                  <fieldset
                    className="ss-top ss-dz"
                    style={{ marginBottom: "-24px" }}
                  >
                    <DropGallery
                      bucket={GALLERY_BUCKET}
                      //locationGallery={this.state.locationGallery}
                      locationId={securityDepositParticularRecordDetails?.id}
                      setDropzone={updateDropzone}
                      updateDropzone={updateDropzone}
                    />
                  </fieldset>
                  <div style={{ marginBottom: "35px" }}></div>
                </>
              )}
              <fieldset
                className="ss-middle"
                style={{
                  borderRadius: "5px",
                  border: "solid 1px #e1e1e1",
                  marginTop: "10px",
                }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  id="notes"
                  name="notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Enter notes regarding the refund status."
                />
              </fieldset>
            </>
          )}
        </div>
        <div className="modal-footer" style={{ border: "none" }}>
          <div
            className="table text-left"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="ss-button-primary-modal-form reverse"
            >
              Cancel
            </button>
            <button
              type="button"
              className="ss-button-primary-modal-form"
              onClick={handleSubmit}
              disabled={
                !(refundStatusName === "Completed"
                  ? !!(modeOfPayment?.trim()?.length > 0) &&
                    !!(modeOfPaymentNumber?.trim()?.length > 0)
                    ? true
                    : false
                  : !refundStatusName
                  ? false
                  : true)
              }
              style={
                !(refundStatusName === "Completed"
                  ? !!(modeOfPayment?.trim()?.length > 0) &&
                    !!(modeOfPaymentNumber?.trim()?.length > 0)
                    ? true
                    : false
                  : !refundStatusName
                  ? false
                  : true)
                  ? { opacity: "0.5" }
                  : {}
              }
            >
              Save
            </button>
          </div>
        </div>
      </form>{" "}
    </>
  );
};

export default EditSecurityDepositForm;
