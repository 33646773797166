import React from "react";
import PropTypes from "prop-types";

const FineTypeSelectMenu = (props) => {
  const { fineTypes, onChange, value } = props;

  return (
    <div>
      <label>Select Fine Type: </label>
      {!!fineTypes && fineTypes.length > 0 && (
        <select
          name="fine type"
          value={value}
          onChange={onChange}
          onClick={onChange}
          style={{ width: "60%", marginLeft: "10px" }}
        >
          <option value="" disabled hidden>
            Please Select
          </option>
          {fineTypes.map((fineType) => (
            <option key={fineType.id} value={fineType.id}>
              {fineType.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

FineTypeSelectMenu.propTypes = {
  fineTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fineType: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default FineTypeSelectMenu;
