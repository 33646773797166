import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "../../css/components/selectInput.css";
import { ArrowDropDown } from "@material-ui/icons";

const LocationSelect = (props) => {
  const {
    value,
    locations,
    onChange,
    setButtonDisabled,
    comp,
    setSelectedZoneName,
  } = props;
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const dropdownRef = useRef(null);

  const handleChange = (location) => {
    onChange(location);
    if (!!setSelectedZoneName)
      setSelectedZoneName(location?.locationZones?.map((ele) => ele?.name));
    setSelectedValue(location?.locationName);
    setShow(false);
    if (!!setButtonDisabled) {
      setButtonDisabled(false);
    }
  };
  const handleClick = () => {
    setShow(!show);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="selectDownDiv" ref={dropdownRef}>
      <label>Select Location: </label>

      <input
        type="text"
        name="locationSelect"
        style={
          comp === "zoneInputWidth"
            ? {
                fontWeight: "200",
                marginLeft: "10px",
                width: "250px",
                // height: "40px",
                cursor: "pointer",
              }
            : {
                fontWeight: "200",
                marginLeft: "10px",
                width: "180px",
                // height: "40px",
                cursor: "pointer",
              }
        }
        value={selectedValue}
        onClick={handleClick}
        placeholder="Please select"
        readOnly
      />
      <ArrowDropDown
        style={{
          position: "absolute",
          marginLeft: "-20px",
          marginTop: "3px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      />
      <div
        className={
          show ? "ss-select-options-visible" : "ss-select-options-hidden"
        }
        style={
          comp === "zoneInputWidth"
            ? { marginTop: "20px", width: "24%", marginLeft: "88px" }
            : { marginTop: "20px", width: "17%", marginLeft: "88px" }
        }
      >
        <ul
          className="ss-select-list"
          style={{ height: "133px", overflowY: "scroll" }}
        >
          {locations &&
            locations.map((location, index) => (
              <li
                id={location.id}
                key={location.id}
                value={location.locationName}
                className="ss-select-item"
                onClick={() => handleChange(location)}
              >
                {location.locationName}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

LocationSelect.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      locationName: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default LocationSelect;
