import React from "react";

const Filter = (props) => {
  const { searchBox, searchChangeHandler, placeHolder } = props;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default "Enter" key behavior (which submits forms)
      // Optionally trigger the search logic here instead of on every change
    }
  };

  return (
    <form className="report-form newFormCol2">
      <div className="data-list-filter-container search-container">
        <fieldset className="trigger-click hs-field">
          <label>FILTER</label>
          <input
            type="text"
            id="searchBox"
            name="searchBox"
            value={searchBox}
            onChange={searchChangeHandler}
            onKeyDown={handleKeyDown}
            placeholder={placeHolder}
          />
          <i
            className="fa fa-search"
            style={{ float: "right", marginTop: "0.25%", marginRight: "1%" }}
          />
        </fieldset>
      </div>
    </form>
  );
};

export default Filter;
