import "bootstrap/dist/css/bootstrap.css";
import "./css/bootstrap-datepicker3.min.css";
import "react-router-modal/css/react-router-modal.css";
import "./App.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.min.css";
import React, { Component } from "react";
import { AppContext } from "./context/app-context";
import TrackJsInitializer from "./services/TrackJsInitializer";
import SessionPoller from "./services/session/SessionPoller";
import SecurSpaceRouter from "./routing/SecurSpaceRouter";
import {
  requestLoggedInAuthoritySource,
  requestLoggedInAuthoritySourceWithToken,
} from "./services/session/session-requests";
import { getLandingRedirectPathForUser } from "./routing/route-utils";
import { Modal } from "react-router-modal";
import Busy from "./components/Busy";
import URLUtils from "./util/URLUtils";
import { checkCookie } from "./components/constants/securspace-constants";
import { toast } from "react-toastify";

export default class SecurSpaceApp extends Component {
  constructor(props) {
    super(props);

    TrackJsInitializer.initialize();

    this.state = {
      user: null,
      loadingUserDetails: true,
      showModal: false,
      isOnline: navigator.onLine, // Initial state to check if user is online
      offlineToastId: null, // To store the ID of the offline toast
    };
  }

  componentDidMount() {
    window.addEventListener("online", this.handleOnline);
    window.addEventListener("offline", this.handleOffline);
    const token = URLUtils.getQueryVariable("token");

    if (token) {
      Busy.set(true);
      // requestLoggedInAuthoritySourceWithToken(token)
      //   .then((resp) => this.handleAlreadyLoggedIn(resp.body))
      //   .catch((err) => this.handleReloadFailure(err));
      console.log("do nothing");
      Busy.set(false);
    } else if (
      !this.state.user ||
      !this.state.user.username
      //&& checkCookie("SESSION")
    ) {
      Busy.set(true);

      requestLoggedInAuthoritySource()
        .then((resp) => this.handleAlreadyLoggedIn(resp.body))
        .catch((err) => this.handleReloadFailure(err));
    } else {
      Busy.set(true);
      this.setState({ loadingUserDetails: false });
      Busy.set(false);
    }
  }

  // Event handler for when the user goes offline
  handleOffline = () => {
    this.setState({ isOnline: false });
    const offlineToastId = toast.error(
      "No internet connection. You are offline!",
      {
        autoClose: false, // Keep the toast open until manually dismissed
      }
    );
    this.setState({ offlineToastId }); // Store the toast ID
  };

  handleOnline = () => {
    this.setState({ isOnline: true });

    // Dismiss the offline toast
    if (this.state.offlineToastId) {
      toast.dismiss(this.state.offlineToastId);
    }

    // Show online toast
    setTimeout(() => {
      toast.success("You are back online!", { autoClose: 2000 });
    }, 400);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  componentWillUnmount() {
    SessionPoller.cancelPolling();
    window.removeEventListener("online", this.handleOnline);
    window.removeEventListener("offline", this.handleOffline);
  }

  handleAlreadyLoggedIn = (account) => {
    this.setState({
      user: account,
      loadingUserDetails: false,
    });
    TrackJsInitializer.updateUserInfo(account);
    SessionPoller.beginPolling(this.handlePollingFail);
    Busy.set(false);
  };

  handleReloadFailure = (err) => {
    this.setState({ loadingUserDetails: false });
    Busy.set(false);
  };

  updateUser = (user, history?: { push: (path: String) => void }) => {
    this.setState({ user });
    TrackJsInitializer.updateUserInfo(user);

    if (!user) {
      SessionPoller.cancelPolling();
    }

    if (history) {
      history.push(getLandingRedirectPathForUser(user));
    }
  };

  handlePollingFail = () => {
    this.clearUser();
    this.setState({ showModal: true });
    /*
            It's not worth re-rendering the entire tree every time
            the url changes to provide react router's history object
            here, so we are just going to do redirect the old fashioned
            way
         */
  };

  clearUser = () => {
    this.setState({ user: undefined });
    TrackJsInitializer.updateUserInfo(null);
    SessionPoller.cancelPolling();
  };

  logout = () => {
    window.location.href = "/logout";
  };

  generateAppContext = () => {
    return {
      user: this.state.user,
      loadingUserDetails: this.state.loadingUserDetails,
      updateUser: this.updateUser,
      clearUser: this.clearUser,
      logout: this.logout,
    };
  };

  render() {
    return (
      <AppContext.Provider value={this.generateAppContext()}>
        <SecurSpaceRouter />
        {this.state.showModal && (
          <Modal className={"modal-form alert-message"}>
            <div style={{ width: "80%", marginTop: "18%", marginLeft: "7%" }}>
              <div className="modal-content">
                <div className={"modal-header d-flex"}>
                  <h5>Sesion Expired!</h5>
                </div>
                <div className="modal-body">
                  <p>Your session has expired due to inactivity.</p>
                  <p>Please login again!</p>
                </div>
                <div
                  className="modal-footer"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    type="button"
                    className="ss-button-primary-modal-form"
                    onClick={() =>
                      (window.location.href = !!URLUtils.getQueryVariable(
                        "token"
                      )
                        ? "https://oaklandportalstage.portofoakland.com/#/"
                        : "/login?timeout=true")
                    }
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </AppContext.Provider>
    );
  }
}
